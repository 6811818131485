.comment-img {
  width: 44px;
  height: 44px;
}

.comment-container {
  padding: 7px 14px;
  border: 1px solid #e4e1ec;
  border-radius: 12px;
  gap: 12px;
  background: #ffffff;
  margin-block: auto;
}

.comment-author {
  opacity: 0.3;
}

.custom-textarea {
  border: none;
  outline: none;
  resize: none;
  background: transparent;
  padding: 0;
  width: 100%;
  height: auto;
}
