@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700&display=swap");

:root {
  --font-family-inter: Inter, sans-serif;
  --font-family-roboto: Roboto, sans-serif;
  --font-family-heading: Inter;
  --font-family-paragraph: Inter;

  --color-text-disabled: #999999;
  --color-text-headings-primary: #000000;
  --color-text-headings-secondary: #7b7b7b;
  --color-border-primary: #dfdfdf;
  --color-border-primary-hover: #3d43bb;
  --color-border-focused: #353ba8;
  --color-border-error: #e3382d;

  --color-surface-secondary: #f5f5fd;

  --color-text-error: #952019;

  --font-style-normal: normal;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  --font-size-xs: 10px;
  --font-size-sm: 12px;
  --font-size-md: 14px;
  --font-size-lg: 16px;
  --font-size-xl: 18px;
  --font-size-2xl: 20px;
  --font-size-3xl: 24px;

  --padding-xs: 8px;
  --padding-sm: 12px;

  --line-height-xs: 16px;
  --line-height-sm: 18px;
  --line-height-md: 20px;
  --line-height-lg: 22px;
  --line-height-xl: 24px;
  --line-height-xxl: 26px;
  --line-height-xxxl: 28px;
  --font-line-height-3xl: 28px;
  --line-height-xxxxl: 32px;
}

/* Font Family */
.paragraph,
.heading,
.overline {
  font-family: var(--font-family-inter);
}

/* Font Weight */
.font-regular {
  font-weight: var(--font-weight-regular);
}

.font-medium {
  font-weight: var(--font-weight-medium);
}

.font-semibold {
  font-weight: var(--font-weight-semibold);
}

.font-bold {
  font-weight: var(--font-weight-bold);
}

/* Font Size */
.font-size-xs {
  font-size: var(--font-size-xs);
}

.font-size-sm {
  font-size: var(--font-size-sm);
}

.font-size-md {
  font-size: var(--font-size-md);
}

.font-size-lg {
  font-size: var(--font-size-lg);
}

.font-size-xl {
  font-size: var(--font-size-xl);
}

.font-size-2xl {
  font-size: var(--font-size-2xl);
}

.font-size-3xl {
  font-size: var(--font-size-3xl);
}

/* Line Height */
.line-height-sm {
  line-height: var(--line-height-sm);
}

.line-height-md {
  line-height: var(--line-height-md);
}

.line-height-lg {
  line-height: var(--line-height-lg);
}

.line-height-xl {
  line-height: var(--line-height-xl);
}

.line-height-xxl {
  line-height: var(--line-height-xxl);
}

.line-height-xxxl {
  line-height: var(--line-height-xxxl);
}

/* Padding */
.padding-xs {
  padding: var(--padding-xs);
}

.padding-sm {
  padding: var(--padding-sm);
}

/* Color Text */
.text-disabled {
  color: var(--color-text-disabled);
}

.text-headings-secondary {
  color: var(--color-text-headings-secondary);
}

.text-error {
  color: var(--color-text-error);
}

/* Border Color */
.border-primary {
  border-color: var(--color-border-primary);
}

.border-primary-hover {
  border-color: var(--color-border-primary-hover);
}

.border-focused {
  border-color: var(--color-border-focused);
}

.border-error {
  border-color: var(--color-border-error);
}

.color-icon-information {
  color: var(--color-border-primary-hover);
}

.color-text-heading-primary {
  color: var(--color-text-headings-primary);
}

.color-text-primary {
  color: var(--color-border-primary-hover);
}

.color-text-body-primary {
  color: var(--color-text-body-primary);
}

.color-text-action-danger {
  color: var(--color-surface-action-danger);
}

.color-text-action {
  color: var(--color-text-action);
}

.color-text-highlight {
  color: var(--color-border-primary-hover);
}

.color-surface-secondary {
  background-color: var(--color-surface-secondary);
}

.heading-h1-regular {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-3xl);
  font-style: normal;
  font-weight: 400;
  line-height: var(--line-height-xxxxl);
}

.heading-h2-semibold {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-2xl);
  font-style: normal;
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xxxl);
}

.heading-h3-semibold {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xxl);
  font-style: normal;
}

.heading-h4-semibold {
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-lg);
  line-height: var(--line-height-xl);
}

.heading-h4-medium {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-xl);
  font-style: var(--font-style-normal);
}

.heading-h5-medium {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-md);
}

.heading-h5-regular {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-md);
}

.heading-h5-semibold {
  font-family: var(--font-family-heading);
  font-size: var(--font-size-md);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-md);
}

.para-body-xs-regular {
  font-family: var(--font-family-inter);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
}

.para-body-xs-medium {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-xs);
}

.para-body-xs-semibold {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xs);
}

.para-body-l-regular {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-lg);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-xl);
}

.para-body-m-regular {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-md);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-lg);
}

.para-body-m-semibold {
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-md);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-lg);
}

.para-body-s-regular {
  font-family: var(--font-family-paragraph);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

.para-body-s-medium {
  font-family: var(--font-family-inter);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

.para-body-s-semibold {
  font-family: var(--font-family-inter);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-sm);
}

.para-body-m-medium {
  font-family: var(--font-family-inter);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-md);
  line-height: var(--line-height-lg);
}

.para-body-m-semibold {
  font-family: var(--font-family-inter);
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-md);
  line-height: var(--line-height-lg);
}

.para-body-l-medium {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-lg);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-xl);
}

.button-small {
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-family: var(--font-family-paragraph) !important;
  font-weight: var(--font-weight-medium) !important;
  line-height: var(--line-height-lg) !important;
}

.button-large {
  font-family: var(--font-family-paragraph) !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: var(--font-line-height-2xl, 26px) !important;
}

.button-medium {
  font-family: var(--font-family-paragraph) !important;
  font-size: var(--font-size-md) !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: var(--line-height-xl) !important;
}

.overline-md {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-xs);
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.title-small {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.table-email {
  color: var(--color-text-body-primary, #7b7b7b);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.alert-description {
  font-family: var(--font-family-roboto);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px;
  letter-spacing: 0.15px;
}

.para-body-xs-regular-important {
  font-family: var(--font-family-inter) !important;
  font-weight: var(--font-weight-regular) !important;
  font-size: var(--font-size-xs) !important;
  line-height: var(--line-height-xs) !important;
}

.para-body-l-regular-important {
  font-family: var(--font-family-inter) !important;
  font-size: var(--font-size-lg) !important;
  font-style: var(--font-style-normal) !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: var(--line-height-xl) !important;
}

.para-body-m-regular-important {
  font-family: var(--font-family-inter) !important;
  font-size: var(--font-size-md) !important;
  font-style: var(--font-style-normal) !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: var(--line-height-lg) !important;
}

.para-body-s-regular-important {
  font-family: var(--font-family-paragraph) !important;
  font-weight: var(--font-weight-regular) !important;
  font-size: var(--font-size-sm) !important;
  line-height: var(--line-height-sm) !important;
}
