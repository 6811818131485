@import "styles/Typography.scss";
@import "styles/Colors.scss";

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff6f6;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: -10px;
  margin-bottom: -7px;

  .inactive-employee-amber-icon {
    color: #d32f2f;
    font-size: 30px;
  }
}

.mark-inactive-message {
  color: var(--color-text-body-primary);
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: 400;
  line-height: var(--font-line-height-lg);

  span {
    font-weight: 600;
  }
}

.inactive-modal-max-words {
  @extend .para-body-s-regular;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 5px;
}

.inactive-employee-message {
  display: flex;
  justify-content: center;
}

.custom-snackbar-message {
  color: var(--color-text-body-inverse);
  font-family: var(--font-family-paragraph);
  font-size: var(--font-size-md);
  font-style: normal;
  font-weight: 600;
  line-height: var(--font-line-height-lg);
  span {
    font-weight: 600;
  }
}

.mark-inactive-done-icon {
  margin-right: 8px;
  margin-top: -3px;
}

.employee-already-inactive-wrapper {
  width: 40rem;
  margin-top: 5px;
}
