@import "styles/Typography.scss";

.add-user-content {
  display: flex;
  align-items: start;
  padding-top: 20px;
  gap: 38px;
}

.user-img-container {
  width: 144px !important;
  height: 144px !important;
}

.user-img-avt {
  width: 144px !important;
  height: 144px !important;
  clip-path: circle();
}

.edit-upload-icon {
  cursor: pointer;
  position: absolute;
  bottom: 2px;
  right: 2px;
  background: white;
  padding: 5px;
  width: 34px;
  height: 34px;
  clip-path: circle();
  display: grid;
  place-items: center;
}

.perm-menu-title {
  @extend .heading-h4-semibold;
}

.perm-menu-description {
  @extend .heading-h4-semibold;
  color: #7b7b7b !important;
}
