.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  padding: 5px 12px;
  border: 1px solid #ececfb;
  border-radius: 24px;
  cursor: pointer;
  outline: none;
  background: #fff;
  min-width: 10rem;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.dropdown-icon {
  align-self: center;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fbfaff !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: -webkit-fill-available;
}

.dropdown-item {
  padding: 9px 7px;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #d9daf2;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
  border-radius: 10px;
}

.selected {
  background-color: #e0e0e0;
  border-radius: 10px;
}
