.no-card-container {
  border-radius: 16px;
  background: #fbfaff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 56px 0px;
}

.no-card-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  color: #666899;
}
