.box-shadow-xs {
  box-shadow: 0px 1px 2px 0px var (--color-elevation-default, rgba(0, 0, 0, 0.16)) !important;
}

.box-shadow-sm {
  box-shadow: 0px 2px 6px 0px var(--color-elevation-default, rgba(0, 0, 0, 0.16)) !important;
}

.box-shadow-md {
  box-shadow: 0px 4px 8px 0px var (--color-elevation-default, rgba(0, 0, 0, 0.16)) !important;
}

.box-shadow-lg {
  box-shadow: 0px 8px 12px 0px var(--color-elevation-default, rgba(0, 0, 0, 0.16)) !important;
}

.box-shadow-xl {
  box-shadow: 0px 16px 20px 0px var(--color-elevation-default, rgba(0, 0, 0, 0.16)) !important;
}