@import "styles/Colors.scss";

.page-header {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins-SemiBold;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
  /* letter-spacing: ; */
}

.page-subheader {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 266.667% */
}

.new-activity-time-off-page-container {
  border-radius: 24px;
  border: 1px solid #dbd9f2;
  background: #fefefe;
  /* width: 1333px; */
  height: 1024px;
  flex-shrink: 0;
  /* padding: 14px; */
  overflow-y: scroll;
}

.search-employee-input {
  display: flex;
  width: 100%;
  height: 30px;
  padding: 7px 14px;

  align-items: flex-start;
  gap: 6.579px;
  flex-shrink: 0;
  border-radius: 19.738px;
  border: 1px solid var(--Primary-90, #e0e0ff);
  background: var(--Primary-100, #fff);
}

.search-employee-input::after {
  content: "\f002";
  font-family: "FontAwesome";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.results-for-short-notice-periods {
  color: #000;
  font-family: Poppins-Medium;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 133.333% */
  /* padding-top: 18px;
  padding-bottom: 16px;
  padding-left: 8px; */
  padding: 0px 14px 16px;
}

.container-employees-results-for-notice-periods {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-start; */

  border: 1px solid var(--Neutral-95, #ececfb);
  border-radius: 24px;
  border: 1px solid #dbd9f2;
  background: #fefefe;
  width: 98.5%;
  /* height: 100%; */
  flex-shrink: 0;
  margin: 0px 14px;
}

.employee-details-on-notice-period {
  display: flex;
  align-items: flex-start;
  gap: 60px;
  overflow-x: scroll;
  padding: 7px 16px;
  /* justify-content: space-between; */
}

.ellipsis-two-line {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}

.employee-sick-leaves {
  color: #3d43bb;
  font-family: Poppins-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.employee-sick-leave-date {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins-SemiBold;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.5px;
}

.time-off-used {
  color: var(--Neutral-20, #292a3d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 120px;
}

.time-off-days {
  display: flex;
  padding: 1px 8px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 13px;
  border: 1px solid var(--neutral-varian-90, #e4e1ec);
}

.time-off-status {
  display: flex;
  padding: 1px 8px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 13px;
}

.status-pending {
  border: 1px solid #ffdf9c;
  background: #ffefd3;
  color: #3f2e00;
}

.status-scheduled {
  border: 1px solid #b9efd8;
  background: #d9ffed;
  color: #007b23;
}

.status-rejected {
  border: 1px solid #ffc6ca;
  background: #ffe4e7;
  color: #97000c;
}

.status-cancelled {
  border: 1px solid #cecece;
  background: #ededed;
  color: #686868;
}

.status-taken {
  border: 1px solid #b9efd8;
  background: #d9ffed;
  color: #007b23;
}

.leave-state-date {
  color: #666899;
  font-family: Poppins-Medium;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  margin-bottom: 7.5px;
  margin-left: 16px;
}

.employee-details-toolbar {
  display: flex;
  padding: 0px 12px 0px 21px;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  border-radius: 36px;
  border: 1px solid var(--Neutral-95, #ececfb);
  background: #fff;
  margin: 0px 13px 12px;
}

.tollbar-text {
  color: #131599;
  font-family: Poppins-Medium !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
}

.overlap-timeoff-comapny-name {
  color: var(--Primary-20, #131599);
  font-family: Poppins-Medium !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 138.462% */
  letter-spacing: 0.15px;
}
