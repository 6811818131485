.ach-loader,
.ach-success-screen {
  min-height: 570px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ach-loading-heading {
  color: #52537a;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.green-check-icon {
  width: 160px;
  height: 160px;
}

.payment-method-success-title {
  color: #14151f;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  width: 90%;
  margin-bottom: 8px;
}

.payment-method-success-description {
  color: #52537a;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 0;
}

.add-accounts-btn {
  position: absolute;
  bottom: 24px;
  width: calc(100% - 48px);
  background: #3d43bb;
}

.custom-popper {
  margin-top: 4px;
  border: 0.5px solid #b2b4e2;
  border-radius: 12px;
  box-shadow:
    0px 1px 1px rgba(2, 0, 110, 0.22),
    0px 1px 6px rgba(2, 0, 110, 0.25);
}

.error-outline-icon {
  position: absolute;
  right: 8px;
  color: #ba1a1a;
}

.save-details-btn {
  background: #3d43bb;
  margin-left: 14px;
}

.section-divider {
  margin: 24px 0 8px 14px !important;
}

.ach-details-field {
  display: flex;
  align-items: center;
  border: 1px solid #b2b4e2;
  border-radius: 12px;
}

.ach-details-field-error {
  border-color: #000000 !important;
}

.routing-account-number {
  display: flex;
  align-items: center;
}

.ach-form-title {
  font-family: Poppins-Medium !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 32px !important;
  color: #1c1b1f !important;
  margin-left: 14px !important;
}

.list-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  word-spacing: 2;
}

.dropdown-value {
  margin-bottom: 0.5rem !important;
  font-family: Poppins-Medium !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.1px !important;
  color: #292a3d !important;
}

.ach-form .MuiFormHelperText-root {
  margin-left: 0px !important;
}
