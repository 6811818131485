.holidays-breadcrumbs-link {
  font-family: "Poppins-Regular" !important;
  font-size: 12px;
  font-weight: 400;
  color: #212231 !important;
  text-decoration: none !important;
}

.holidays-main-header-title {
  color: #14151f;
  font-family: "Poppins-Semibold" !important;
  font-size: 20px !important;
  padding-left: 5px;
  font-weight: 600;
  line-height: 32px;
}
