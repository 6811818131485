@import "styles/Typography.scss";

.page-toolbar-wrapper {
  padding: 4px 0;
  gap: 8px;
  border: 1px solid var(--color-border-primary);
  height: 35px;
  display: flex;
  box-shadow: 0px 1px 6px 0px #02006e0d 0px 3px 5px 0px #02006e00;
  align-items: center;
  border-radius: 99px;
  background-color: #fff;
  height: 44px;
}

.page-toolbar-tab {
  &.primary {
    background: var(--color-surface-action-primary) !important;
  }

  &:hover {
    background: var(--color-surface-action-secondary);
  }

  border: none;
  height: 36px;
  padding: 7px 12px !important;
  margin-left: 2px;
}

.toolbar-title {
  @extend .para-body-m-medium;

  padding: 0 12px !important;
}
