@import "styles/Colors.scss";

.employee-info-avatar {
  position: relative;
}

.employee-info-name,
.employee-info-date,
.employee-info-managed-by {
  color: var(--color-primitives-black-black);
}
.employee-info-name {
  height: 24px !important;
}

.employee-info-job-title {
  color: var(--color-brand-primary-blue-600);
  height: 20px !important;
  display: flex;
  align-items: center;
}

.employee-info-detail-section {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
