.document-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.download-title {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  color: #131599;
  text-decoration: underline;
  cursor: pointer;
}

.prescription-list {
  padding-left: 12px;
  padding-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
}

.prescription-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  border-radius: 12px;
  padding: 8px 6px;
  border: 1px;
  border: 1px solid #f1efff;
  width: calc(50% - 10px);
}

.file-name {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 9px;
  text-wrap: nowrap;
    text-overflow: ellipsis;
    max-width: 370px;
    overflow: hidden;
  color: #131599;
  cursor: pointer;
}

.file-size {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #989ad1;
  text-transform: capitalize;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .prescription-item {
    width: 100%;
  }
}
