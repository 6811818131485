.pd-container {
  width: 894px;
  border-radius: 10px;
  margin: auto;
  box-shadow: 0px 1px 14px 0px rgba(2, 0, 110, 0.12);
  background: white;
  overflow: scroll;
  overflow-y: hidden;
}

.pd-failure-btns-container {
  display: flex;
}

.pd-header {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(92deg, #131599 17.22%, #0a0b6b 84%);
  color: white;
  padding: 1.1rem 4.7rem;
  border-radius: 12px 12px 0 0;
  background-repeat: no-repeat !important;
}

.pd-header-title {
  font-family: Poppins-Regular !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  margin-top: 1rem !important;
}

.pd-header-value {
  font-family: Poppins-Regular;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
}

.pd-header-white-text {
  color: var(--tertiary-100, #fff);
}

.pd-header-black-text {
  color: #000000;
}

.pd-payment-overview {
  padding: 1.1rem 4.7rem;
}

.pd-payment-overview-heading {
  color: var(--neutral-10, #14151f);
  font-family: Poppins-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin: 0;
  padding-bottom: 14px;
}

.pd-payment-overview-details {
  display: flex;
  justify-content: space-between;
}

.pd-payment-overview-details p {
  margin: 0.4rem 0;
  color: var(--neutral-10, #14151f);
  font-family: Poppins-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.pd-failure-reason {
  color: #ba1a1a;
  font-family: Poppins-Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20x;
  letter-spacing: 0.5px;
  background-color: #ffedea;
  margin: 0.8rem 4.7rem 0 4.7rem;
  padding: 12px 16px;
  border-radius: 10px;
}

.pd-pending-reason {
  color: #5b4300;
  font-family: Poppins-Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20x;
  letter-spacing: 0.5px;
  background-color: #ffefd3;
  margin: 0.8rem 4.7rem 0 4.7rem;
  padding: 12px 16px;
  border-radius: 10px;
}
