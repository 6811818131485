@import "styles/Typography.scss";

.date-picker-container {
  .sb-main-padded {
    padding-left: 0px !important;
  }

  .MuiTextField-root {
    min-width: 210px !important;
  }

  .MuiBox-root {
    color: var(--color-text-headings-secondary) !important;
    @extend .para-body-m-medium;
    margin-bottom: 4px;
    height: 18px;
  }

  .MuiFormHelperText-root {
    color: var(--color-primitives-danger-600) !important;
    font-family: var(--font-family-inter);
  }

  .MuiPickersDay-root {
    @extend .para-body-m-regular;
    color: var(--color-text-body-secondary);
    text-align: center;
    min-height: 20px !important;
    border-radius: 45px;
    margin-bottom: 1px;
    background-color: transparent !important;
  }

  .MuiTypography-root {
    font-family: var(--font-family-inter) !important;
    font-size: var(--font-size-md) !important;
    font-style: var(--font-style-normal) !important;
  }

  .MuiTypography-root.Mui-selected {
    background-color: var(--color-primitives-primary-600) !important;
    max-width: 85.33334px !important;
    margin-left: 8px !important;
    margin-right: 10px !important;
  }

  .MuiPickersDay-root.Mui-selected {
    color: var(--color-primitives-white-white) !important;
    border: 1px solid var(--color-border-black) !important;
    background-color: var(--color-primitives-primary-600) !important;
  }

  .MuiInputBase-input::placeholder {
    flex: 1 0 0;
    color: var(--color-text-disabled);
    font-family: var(--font-family-inter);
    font-size: var(--font-size-lg);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-medium);
  }

  .MuiInputAdornment-root svg {
    width: 24px;
    height: 24px;
    fill: var(--color-brand-primary-grey-400) !important;
  }

  .MuiCalendarPicker-root {
    box-shadow: none !important;
  }

  .PrivatePickersYear-yearButton {
    font-family: var(--font-family-inter) !important;
  }

  .PrivatePickersYear-yearButton.Mui-selected {
    background-color: var(--color-primitives-primary-600) !important;
  }

  .MuiOutlinedInput-root {
    font-family: var(--font-family-inter) !important;
    font-size: var(--font-size-lg);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-medium);
    height: 40px !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--color-border-primary) !important;
  }

  .css-1v994a0 {
    @extend .para-body-l-medium;
    color: var(--color-text-body-secondary);
  }

  .MuiPaper-root {
    margin-left: 20px;
  }
  &.has-error {
    .MuiOutlinedInput-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--color-primitives-danger-600) !important;
      }
    }
  }
  &.is-disabled {
    .MuiOutlinedInput-root {
      border-radius: var(--borderRadius);
      background: var(--color-surface-primary-disabled);
    }
  }
}
