.dialog-header-wrapper {
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 44px;
  background: var(--color-surface-danger);
}
