:root {
  --border-radius-xs: 2px;
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 16px;
  --border-radius-xl: 32px;
  --border-radius-full: 999px;
  --border-radius-pill: 999px;
}

.border-radius-full {
  border-radius: var(--border-radius-full);
}