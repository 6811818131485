@import "styles/Colors.scss";

.auth0-button {
  background-color: var(--color-primitives-white-white) !important;
  border-radius: 99px !important;
  color: var(--color-primitives-primary-600) !important;
  height: 54px !important;
  box-shadow: 2px 5px 7px rgba(2, 0, 110, 0.12) !important;
  gap: 10px !important;

  &:hover {
    background-color: var(--color-primitives-primary-600) !important;
    color: var(--color-primitives-white-white) !important;
  }
}

.auth0-button-logo {
  margin-left: -30px;
}