.ep-payroll-bar {
  background-color: #ffffff;
  border-radius: 24px;
  width: 90%;
  margin: 0px auto;
}
.ep-payroll-bar-meta {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  align-items: baseline;
}
.ep-payroll-last-month {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.ep-payroll-dollar {
  font-family: Poppins-Regular !important;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  color: #14151f;
  margin-top: 0.4rem;
  width: 21px;
}
.ep-payroll-amount {
  font-family: Poppins-Regular !important;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
  color: #14151f;
}
.ep-payroll-gross {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-top: 4px;
}

.ep-payroll-bonus-raise {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #666899;
  margin-top: 4px;
}

@media only screen and (min-width: 900px) {
  .ep-payroll-bar {
    /* width: auto;
    flex: 0.5; */
    width: 42%;
  }
}
