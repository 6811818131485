.workforce-overview-container {
  float: left;
  width: 100%;
  padding-bottom: 16px;
}

.employee-info-wrapper {
  float: left;
  width: 63%;
}

.personal-info-container {
  margin-bottom: 40px;
}

.avatar-wrapper {
  width: 280px;
}

.avatar-emp-name {
  margin-top: 20px;
}

.avatar-container {
  float: left;
  width: 37%;
  padding-left: 150px;
}

.employee-overview-avatar {
  width: 280px !important;
  height: 280px !important;
  clip-path: circle();
}

.camera-image-upload-icon {
  cursor: pointer;
  position: absolute;
  bottom: 12px;
  right: 26px;
  background: white;
  clip-path: circle();
  padding: 12px;
  display: grid;
  place-items: center;
}
