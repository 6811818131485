.employee-stubs-grid-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.employee-stubs-grid-header-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}
.employee-stubs-grid-header-filters-btn {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  border: 1px solid #777680;
  border-radius: 22px;
  padding: 8px 20px;
  background: #ffffff;
  cursor: pointer;
  transition: 0.2s ease-in;
}
.employee-stubs-grid-header-filters-btn:hover {
  background: #ececfb;
}
.employee-stubs-grid-header-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px;
}
.employee-label-tax {
  width: 30px;
  height: 15px;
  background: linear-gradient(243deg, #7d84fe 0%, #e0e0ff 100%);
  color: #9ea3ff;
  margin: 0px 10px;
  border-radius: 22px;
}
.employee-label-bonus {
  width: 30px;
  height: 15px;
  background-color: #4950c7;
  color: #4950c7;
  margin: 0px 10px;
  border-radius: 22px;
}
.employee-label-net {
  width: 30px;
  height: 15px;
  background: linear-gradient(20deg, #b4fff2 0%, #9ea3ff 100%);
  color: #f1efff;
  margin: 0px 10px;
  border-radius: 22px;
}
.employee-label-text {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.employee-stubs-grid-cella {
  padding: 10px;
  gap: 5px;
  width: 30%;
}

.employee-stubs-grid-cellb {
  padding: 10px;
  width: 25%;
}

.employee-stubs-grid-cellDate {
  text-align: left;
  font-family: Poppins-Medium !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #292a3d;
}
.employee-stubs-grid-cellPayDate {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #4950c7;
}
.employee-stubs-grid-cellMonthOf {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #292a3d;
}

.employee-stubs-grid-cellc {
  padding: 10px;
  gap: 10px;
  display: flex;
  flex-direction: row;
  width: 45%;
  justify-content: space-between;
}

.employee-stubs-grid-bar {
  border: 1px solid rgb(248, 248, 248);
  display: flex;
  border-radius: 26px;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
}
.employee-stubs-grid-bartax {
  background: linear-gradient(243deg, #7d84fe 0%, #e0e0ff 100%);
  color: #9ea3ff;
  height: 15px;
  overflow: hidden;
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 1;
  border-radius: 8px;
}

.employee-stubs-grid-barbonus {
  background-color: #4950c7;
  color: #4950c7;
  height: 15px;
  overflow: hidden;
  grid-row-start: 1;
  z-index: 1;
  grid-column-start: 1;
  border-radius: 8px;
}

.employee-stubs-grid-barnet {
  background: linear-gradient(20deg, #b4fff2 0%, #9ea3ff 100%);
  color: #f1efff;
  height: 15px;
  overflow: hidden;
  grid-row-start: 1;
  grid-column-start: 1;
  border-radius: 8px;
}

.employee-stubs-grid-celld {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}

.celld-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px;
  gap: 8px;
  background: #e0e0ff;
  border-radius: 42px;
  border: none;
  justify-content: flex-end;
}

.detail-paystub-view-icon {
  object-fit: contain;
  margin-left: 12px;
  cursor: pointer;
  transform: scale(0.85);
  padding: 10px;
}

.detail-paystub-view-icon:hover {
  background: #ececfb;
  clip-path: circle();
}

.header-one {
  width: 30%;
}

.header-two {
  width: 25%;
}

.header-three {
  width: 45%;
}

.no-payslips-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  border-radius: 12px;
  background: #fff;
  margin-top: 24px;
}

.no-payslips-content {
  text-align: center;
}

.no-payslips-title {
  color: #292a3d;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin-block: 10px;
}

.no-payslips-img {
  width: 86px;
  height: 104.192px;
}

.no-payslips-details {
  color: #918f9a;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  width: 80%;
  margin-inline: auto;
}
