.bhr-education-container {
  display: flex;
  width: full;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.bhr-education-container .bhr-school-icon {
  width: 64px;
  height: 64px;
  color: var(--color-icon-secondary);
}

.timeline-more-icon {
  width: 24px;
  height: 24px;
  color: var(--color-icon-information-inverse);
  position: relative;
}

.icon-wrapper {
  position: relative;
}

.paper-style {
  position: absolute;
  width: 160px;
  padding: 8px 0px;
  right: 5px;
}

.edit-menu-items {
  cursor: pointer;
  padding: 8px 12px;
  transition: color 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}

.no-eduaction-container {
  padding: 16px 24px;
  border-radius: 8px;
  margin-top: 15px;
  background-color: var(--color-surface-primary-hover);
}
