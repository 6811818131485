.pd-breakdown-table {
  transition: all 0.5s linear;
}

.pd-employee-breakdown {
  cursor: pointer;
}

.pd-employee-breakdown-heading {
  display: flex;
  justify-content: space-between;
  padding: 1.1rem 4.7rem;
  color: var(--neutral-20, #292a3d);
  font-family: Poppins-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.15px;
  transition: background-color 0.2s ease-in;
  margin: 0;
}

.pd-employee-breakdown-heading:hover {
  background: #fbfaff;
}

.pd-breakdown-table-head {
  float: left;
  width: 100%;
  background: #f1efff;
  color: var(--primary-0, #000);
  font-family: Poppins-Regular;
  font-size: 13px;
  font-weight: 500;
}

.pd-breakdown-table-head-name {
  float: left;
  width: 33%;
  padding-left: 4.7rem;
  padding-top: 6px;
  padding-bottom: 6px;
}

.pd-breakdown-table-head-value {
  float: left;
  width: 20%;
  border-left: 0.1px solid #9ea3ff;
  padding-left: 1rem;
  padding-top: 6px;
  padding-bottom: 6px;
}

.pd-breakdown-table-head-total {
  float: left;
  width: 24%;
  border-left: 0.1px solid #9ea3ff;
  padding-left: 1rem;
  padding-top: 6px;
  padding-bottom: 6px;
}

/* .pd-breakdown-table-body {
  padding: 0 4.7rem;
} */

.pd-breakdown-table-body img {
  clip-path: circle();
  width: 3.5rem;
  height: 3.5rem;
}

.pd-breakdown-table-name {
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-left: 12px;
}

.pd-breakdown-table-name p {
  margin: 0;
}

.pd-breakdown-table-name-container {
  float: left;
  width: 100%;
  padding: 14px 0;
  display: flex;
}

.pd-breakdown-table-name-container-1 {
  float: left;
  width: 33%;
  display: flex;
  padding-left: 4.7rem;
}

.pd-breakdown-table-name-container-2 {
  float: left;
  width: 20%;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  align-self: center;
}

.pd-breakdown-table-name-container-4 {
  float: left;
  width: 24%;
  display: flex;
  padding-left: 1rem;
  align-self: center;
}

.pd-breakdown-table-fullname {
  color: var(--tertiary-10, #00201c);
  font-family: Poppins-Medium;
  font-size: 14px;
  font-weight: 500;
}

.pd-breakdown-table-designation {
  color: var(--neutral-50, #666899);
  font-family: Poppins-Regular;
  font-size: 12px;
  font-weight: 400;
}

.pd-breakdown-table-amount {
  color: var(--tertiary-10, #00201c);
  font-family: Poppins-Medium;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.pd-employee-subtext {
  color: var(--primary-20, #131599);
  font-family: Poppins-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.pd-hidden {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

.toggleDiv {
  transition: opacity 500ms ease-in, max-height 500ms ease-in;
  opacity: 0;
  max-height: 0px;
  overflow: hidden;
}

.toggleDiv.visible {
  opacity: 1;
  max-height: 300px;
  overflow: scroll;
}

.chevronIcon.rotated {
  transform: rotate(180deg);
}
