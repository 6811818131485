.payroll-history-reasons {
  transform-origin: top;
  transform: scaleY(0);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  visibility: collapse;
}

.payroll-history-reasons.open {
  transform: scaleY(1);
  transition: transform 0.3s ease-in-out;
  visibility: visible;
}

.payroll-history-reasons td {
  border-bottom: none;
}

.un-hide {
  display: table-row !important;
}

.un-hide-block {
  visibility: visible !important;
}

.payroll-failureReason-text {
  background-color: white;
  border-radius: 12px;
  padding: 13px;
  color: var(--neutral-10, #14151f);
  font-family: Poppins-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.payroll-right-arrow {
  visibility: hidden;
  transform: rotate(270deg);
}

.payroll-history-table-header {
  font-family: Poppins-Regular !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 12px !important;
  border-bottom: none !important;
  /* width: 20%; */
  color:#292A3D !important;
}

.payroll-history-table-cell {
  font-family: Poppins-Medium !important; 
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border-bottom: none !important;
  /* padding-left: 24px !important; */
}

.add-bonus-input .MuiOutlinedInput-root {
  font-family: "Poppins-Medium" !important;
  font-size: 16px !important;
  line-height: 24px;
  color: #000000;
}

