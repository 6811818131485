@import url("styles/Colors.scss");

.delete-modal-share-with-text {
  color: var(--color-text-action) !important;
}

.delete-modal-created-at-text {
  color: var(--color-text-body-primary) !important;
}

.no-document-icon {
  color: var(--color-icon-secondary);
  font-size: 32px !important;
}

.documents-container {
  background-color: var(--color-surface-page-primary);
}

.download-icon {
  color: var(--color-icon-secondary);
}

.no-documents-shared {
  background-color: var(--color-brand-primary-grey-50);
  border-radius: var(--border-radius-md);
}

.no-documents-shared-text {
  color: var(--color-text-headings-secondary);
}

.no-docs-icon-button {
  background-color: var(--color-surface-primary-hover) !important;
  height: 48px;
  width: 48px;
}

.light-grey-text {
  color: var(--color-brand-primary-grey-700, #7b7b7b);
}
