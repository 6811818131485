@import "styles/Colors.scss";
@import "styles/BorderRadius.scss";
@import "styles/BoxShadows.scss";

.button {
    text-transform: none !important;
    border-radius: var(--border-radius-full) !important;
    font-family: var(--font-family-inter) !important;
    box-shadow: none !important;
}

.button:hover {
    @extend .box-shadow-sm;
}

.button:focus {
    @extend .box-shadow-sm;
}

.button-small-padding {
    padding: 4px 10px !important;
}

.button-medium-padding {
    padding: 6px 16px !important;
}

.button-large-padding {
    padding: 8px 22px !important;
}   

// contained classes
.button-contained-primary {
    background: var(--color-surface-action-primary) !important;
    color: var(--color-text-on-action-white) !important;
}

.button-contained-primary:hover {
    background: var(--color-surface-action-primary-hover) !important;
}

.button-contained-secondary {
    background: var(--color-surface-action-secondary) !important;
    color: var(--color-text-action) !important;
}

.button-contained-secondary:hover {
    background: var(--color-surface-action-secondary-hover) !important;
}

.button-contained-error {
    background: var(--color-surface-action-danger) !important;
    color: var(--color-text-on-action-white) !important;
}

.button-contained-error:hover {
    background: var(--color-surface-action-danger-hover) !important;
}

.button-contained-warning {
    background: var(--color-surface-action-warning) !important;
    color: var(--color-text-on-action-white) !important;
}

.button-contained-warning:hover {
    background: var(--color-surface-action-warning-hover) !important;
}

.button-contained-info {
    background: var(--color-surface-action-primary) !important;
    color: var(--color-text-on-action-white) !important;
}

.button-contained-info:hover {
    background: var(--color-surface-action-primary-hover) !important;
}

.button-contained-success {
    background: var(--color-surface-action-success) !important;
    color: var(--color-text-on-action-white) !important;
}

.button-contained-success:hover {
    background: var(--color-surface-action-success-hover) !important;
}

.button-contained-inherit-white {
    background: var(--color-primitives-neutral-200) !important;
    color: var(--color-text-on-action-white) !important;
}

.button-contained-inherit-white:hover {
    background: var(--color-primitives-neutral-50) !important;
}

.button-contained-inherit-text {
    background: var(--color-surface-action-neutral) !important;
    color: var(--color-text-on-action-black) !important;
}

.button-contained-inherit-text:hover {
    background: var(--color-surface-action-neutral-hover) !important;
}

// outlined classes
.button-outlined-primary {
    border: 1px solid var(--color-border-action) !important;
    color: var(--color-text-action) !important;
}

.button-outlined-primary:hover {
    background: var(--color-surface-action-secondary) !important;
}

.button-outlined-secondary {
    border: 1px solid var(--color-primitives-primary-200) !important;
    color: var(--color-text-action) !important;
}

.button-outlined-secondary:hover {
    background: var(--color-primitives-primary-100) !important;
}

.button-outlined-error {
    border: 1px solid var(--color-border-danger) !important;
    color: var(--color-text-danger) !important;
}

.button-outlined-error:hover {
    background: var(--color-primitives-danger-100) !important;
}

.button-outlined-warning {
    border: 1px solid var(--color-border-warning) !important;
    color: var(--color-text-warning) !important;
}

.button-outlined-warning:hover {
    background: var(--color-primitives-warning-100) !important;
}

.button-outlined-info {
    border: 1px solid var(--color-primitives-primary-200) !important;
    color: var(--color-text-action) !important;
}

.button-outlined-info:hover {
    background: var(--color-primitives-primary-100) !important;
}

.button-outlined-success {
    border: 1px solid var(--color-border-success) !important;
    color: var(--color-text-success) !important;
}

.button-outlined-success:hover {
    background: var(--color-primitives-success-100) !important;
}

.button-outlined-inherit-text {
    border: 1px solid var(--color-border-neutral) !important;
    color: var(--color-text-body-secondary) !important;
}

.button-outlined-inherit-text:hover {
    background: var(--color-primitives-neutral-200) !important;
}

.button-outlined-inherit-white {
    border: 1px solid var(--color-border-tertiary) !important;
    color: var(--color-text-on-action-white) !important;
}

.button-outlined-inherit-white:hover {
    background: var(--color-primitives-neutral-100) !important;
}

// text classes
.button-text-primary {
    color: var(--color-text-action) !important;
}

.button-text-primary:hover {
    background: var(--color-surface-action-secondary) !important;
}

.button-text-secondary {
    color: var(--color-primitives-neutral-600) !important;
}

.button-text-secondary:hover {
    background: var(--color-primitives-neutral-100) !important;
}

.button-text-error {
    color: var(--color-text-danger) !important;
}

.button-text-error:hover {
    background: var(--color-primitives-danger-100) !important;
}

.button-text-warning {
    color: var(--color-text-warning) !important;
}

.button-text-warning:hover {
    background: var(--color-primitives-warning-100) !important;
}

.button-text-info {
    color: var(--color-text-action) !important;
}

.button-text-info:hover {
    background: var(--color-surface-action-secondary) !important;
}

.button-text-success {
    color: var(--color-text-success) !important;
}

.button-text-success:hover {
    background: var(--color-primitives-success-100) !important;
}

.button-text-inherit-text {
    color: var(--color-text-body-secondary) !important;
}

.button-text-inherit-text:hover {
    background: var(--color-primitives-neutral-200) !important;
}

.button-text-inherit-white {
    color: var(--color-text-on-action-white) !important;
}

.button-text-inherit-white:hover {
    background: var(--color-primitives-neutral-100) !important;
}

// disabled classes
.button-contained-disabled {
    background: var(--color-surface-action-primary-disabled) !important;
    color: var(--color-text-on-disabled) !important;
}

.button-outlined-disabled {
    border: 1px solid var(--color-surface-action-primary-disabled) !important;
    color: var(--color-text-on-disabled) !important;
    background-color: transparent !important;
}

.button-text-disabled {
    background-color: transparent !important;
    color: var(--color-text-on-disabled) !important;
}

// loading classes
.button-contained-loading {
    background: var(--color-surface-action-primary-disabled) !important;
    color: var(--color-icon-secondary) !important;
    border: none !important;
}

.button-outlined-loading {
    background: transparent !important;
    color: var(--color-icon-secondary) !important;
    border: 1px solid var(--color-surface-action-primary-disabled) !important;
}

.button-text-loading {
    background: transparent !important;
    color: var(--color-icon-secondary) !important;
    border: none !important;
}

.no-hover {
  &:hover {
    background-color: inherit !important;
    box-shadow: none !important;
    cursor: pointer !important;
  }
}