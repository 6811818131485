.manage-timeoff-wrapper {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
}

.manage-timeoff-container {
  width: 64.5rem;
  margin: 0 auto;
}

.upcoming-leaves-margin {
  margin: 1rem auto;
}

.sidebar {
  border-radius: 24px;
  border: 1px solid hsl(236deg 100% 94% / 50%);
  width: fit-content;
  padding: 1rem 1.1rem 2rem 1rem;
}
