.profile-info-container {
  max-width: 1076px;
  width: 100%;
  margin: 0 auto;
  margin-top: 32px;
  padding-top: 0px;
}

.profile-main {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.personal-info {
  min-width: 712px;
}

.profile-picture {
  max-width: 260px;
  width: 100%;
}

.profile-img-container {
  width: 257px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #dfdfdf;
  background: #f7f7f7;
}
