@import "styles/Typography.scss";

.timeline {
  position: relative;
  margin: 20px 0;
  padding-left: 30px;
}

.timeline-item {
  position: relative;
  margin-bottom: 20px;
  padding-left: 13px;
}

.timeline-dot {
  position: absolute;
  left: -12px;
  top: 24px;
  width: 10px;
  height: 10px;
  background-color: #cbccef;
  border-radius: 50%;
  z-index: 2;
}

.timeline-content {
  margin-left: 20px;
}

.timeline-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -13px;
}

.timeline-divider {
  margin-bottom: 25px !important;
  flex-grow: 1;
}

.timeline-title {
  @extend .heading-h4-medium;
  color: var(--color-text-headings-primary);
  margin-right: 15px;
}

.timeline-date span {
  @extend .para-body-m-medium;
  font-style: var(--font-style-normal);
  color: var(--color-text-headings-primary);
  margin-left: 15px;
}

.timeline-company {
  @extend .para-body-m-medium;
  color: var(--color-text-action);
  font-style: var(--font-style-normal);
  text-decoration: none;
}

.timeline-details {
  padding-left: 20px;
  margin-top: 10px;
}

.timeline-details li {
  @extend .para-body-m-regular;
  margin-bottom: 5px;
  color: var(--color-text-body-primary);
  margin-left: 4px;
  width: 100%;
}

.timeline::before {
  content: "";
  position: absolute;
  left: 22px;
  transform: translateX(0.5px);
  top: -5px;
  bottom: -20px;
  width: 1px;
  background-image: linear-gradient(to bottom, #cbccef 50%, rgba(255, 255, 255, 0) 0%);
  background-size: 2px 2px;
  background-repeat: repeat-y;
  z-index: 1;
}

.time-line-icon {
  .MuiSvgIcon-root {
    position: relative;
    top: 4px;
    left: 24px;
    color: var(--color-brand-primary-grey-400);
    cursor: pointer !important;
  }
}

.time-line-icon:hover {
  .MuiSvgIcon-root {
    color: var(--color-brand-primary-grey-500);
    background-color: #fafafaf8 !important;
    border-radius: 15px;
  }
}
