.profile-container {
  background-color: #ffffff;
  height: calc(100vh - 46px);
  overflow: scroll;
}

.ep-profile-main {
  /* background-color: red; */
  /* width: 950px; */
  margin: auto;
}
.employee-profile-tab-container{
  min-width: 700px;
}
.employee-profile-d21 {
  margin: 60px 20px 10px 10px;
  background: #ffffff;
  border: 1px solid #ececfb;
  box-shadow: 0px 1px 6px rgba(2, 0, 110, 0.05);
  border-radius: 24px;
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  padding: 5px;
}
.employee-profile-d21 > div {
  border-radius: 25px;
  padding: 8px;
  font-family: Poppins-Regular !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.1px;
}
.option-active {
  background: #3035ae;
  color: #f9f9fb;
}
.employee-profile-d22 {
  border-radius: 24px;
}
.employee-profile-d22A {
  background: #ffffff !important;
  border-radius: 20px;
}

@media only screen and (min-width: 900px) {
  .employee-profile-d21 {
    margin: 15px;
  }
}

.profile-heading{
  padding-left: 40px;
}