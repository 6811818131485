@import "styles/Typography.scss";
@import "styles/Colors.scss";

.tooltip-wrapper {
  width: 224px;
}

.inactive-chip-wrapper {
  width: fit-content;
}

.tooltip-text {
  @extend .para-body-xs-regular;
  color: var(--color-text-body-inverse);
}

// Todo: change the below code and use semi-bold-xs class from typography when available
.tooltip-bold {
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-semibold);
  line-height: var(--line-height-xs);
  color: var(--color-text-body-inverse);
}
