.pending-chip {
  background: #3f2e00 !important;
  background: #ffefd3 !important;
  border: 1px solid #ffdf9c !important;
}

.pending-icon {
  color: #007b23 !important;
}

.scheduled-chip {
  color: #007b23 !important;
  background: #d9ffed !important;
  border: 1px solid #b9efd8 !important;
}

.taken-chip {
  color: #007b23 !important;
  background: #d9ffed !important;
  border: 1px solid #b9efd8 !important;
  gap: 8px;
}

.taken-chip img {
}

.taken-chip span {
  padding-left: 0;
}

.scheduled-icon {
  color: #007b23 !important;
  transform: scale(1);
}

.rejected-chip {
  color: #97000c !important;
  background: #ffe4e7 !important;
  border: 1px solid #ffc6ca !important;
}

.rejected-icon {
  transform: scale(1);
  color: #97000c;
}

.cancelled-chip {
  color: #686868 !important;
  background: #ededed !important;
  border: 1px solid #cecece !important;
  gap: 4px;
}

.cancelled-icon {
  transform: scale(1);
  color: #cecece;
}
