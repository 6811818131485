.remove-manager-employee-card {
  background-color: var(--color-brand-primary-grey-50) !important;
}

.warning-icon-button {
  background-color: var(--color-primitives-danger-50) !important;
  height: 48px;
  width: 48px;
  border-radius: 44px;
}

.warning-icon {
  height: 28px;
  width: 28px;
  color: var(--color-surface-action-danger) !important;
}

.remove-manager-text {
  color: var(--color-text-headings-secondary) !important;
}
