@import "styles/Typography.scss";
@import "styles/Colors.scss";

.custom-dialog {
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.custom-dialog-title {
  color: var(--color-text-headings-primary) !important;
  font-family: var(--font-family-paragraph) !important;
  font-size: var(--font-size-2xl) !important;
  font-style: normal !important;
  font-weight: var(--font-weight-semibold) !important;
  line-height: var(--line-height-xxxl) !important;
  padding: 0 !important;
}

.custom-dialog-content {
  padding: 0 !important;

  &.with-dividers {
    border-top: 1px solid #0000001f;
    border-bottom: 1px solid #0000001f;

    & > * {
      padding: 16px 0;
    }
  }
}

.custom-dialog-actions {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: end;
}
