.employee-documents-A {
  background-color: #fbfaff;
  height: calc(100vh - 46px);
}
.employee-documentsA-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 1076px;
  margin: auto;
  flex-wrap: wrap;
}
.employee-documentA-list-item {
  background: #ffffff;
  border-radius: 24px;
  min-width: 180px;
}
.employee-docs-icon {
  display: flex;
  justify-content: center;
  margin: 10px 0px;
  
}


.employee-docs-edit-icon {
  display: flex;
  justify-content: end;
  margin: 12px 10px 0px 0px;

}
.employee-docs-title {
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.1px;
  margin: 10px auto;
  max-width: 150px;
  overflow: hidden;
text-overflow: ellipsis;
-webkit-line-clamp: 2;
display: -webkit-box;
-webkit-box-orient: vertical;
overflow: hidden;
 -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
white-space: pre-wrap; 
height:37px
}
.employee-docs-file-limit {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
  padding: 5px;
  background-color: #fbfaff;
}

.employee-docs-file-uploaded {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  padding: 3px;
  background-color: #f2fffb;
  margin-bottom: 10px;
  max-width: 180px;
  overflow: hidden;
text-overflow: ellipsis;
-webkit-line-clamp: 1;
display: -webkit-box;
-webkit-box-orient: vertical;
overflow: hidden;
 -webkit-line-clamp: 1; /* start showing ellipsis when 3rd line is reached */
white-space: pre-wrap; 
/* height: 37px */
}

.customer-employee-docs {
  max-width: 180px;
  overflow: hidden;
text-overflow: ellipsis;
-webkit-line-clamp: 2;
display: -webkit-box;
-webkit-box-orient: vertical;
overflow: hidden;
 -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
white-space: pre-wrap; 
}

.employee-docs-file-view {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 15px;
}
.view-btn {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.4px;
}

@media only screen and (min-width: 900px) {
  .employee-documentsA-list {
    flex-direction: row;
  }
}
