.icon-style {
  color: #fff;
  width: 20px;
  height: 21px;
}

.list-item-button {
  padding: 0px 23px !important;
}

.list-item-icon {
  min-width: 40px !important;
}

.edge-logo-style {
  margin-bottom: 15px;
  padding: 0px 25px;
}
