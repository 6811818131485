.service-header-body {
  background: linear-gradient(80deg, #f1efff 7.2%, #9ea3ff 248.96%);
  padding-bottom: 2%;
}

.service-edge-logo {
  width: 97px;
  height: 50px;
  flex-shrink: 0;
}

.service-sub-heading {
  margin-top: 1.5rem !important;
  color: var(--Primary-10, #02006e);
  font-family: "Inter";
  font-size: 16px;
  opacity: 0.5;
}