.timeoff-taken-tab {
  display: flex;
  width: 100%;
  border: 1px solid #d9daf2;
  border-radius: 20px;
}

.timeoff-taken-tab .leave-count {
  border-right: #d9daf2;
  padding: 8px 16px;
  padding-top: 12px;
}

.timeoff-history-container {
  border: 1px solid #d9daf2;
  border-radius: 20px;
}

.leave-count-scheduled-pending {
  color: #049084;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-top: 10px;
}

.leave-type-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time-off-not-available {
  width: 100%;
  padding: 24px;
  border: 1px solid #f1efff;
  background: #fbfaff;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  font-family: "Poppins-Regular";
  font-weight: 400;
  border-radius: 12px;
}

.timeoff-valid {
  font-family: "Poppins-Regular";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.timeoff-valid-date {
  font-family: "Poppins-Regular";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1px;
}
