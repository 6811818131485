.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  background-color: #fff;
  min-height: 95vh;
}

.row {
  margin-top: 29px;
  border-bottom: 1px solid #d9daf2;
}

.inner-container {
  max-width: 1076px;
  width: 100%;
  margin: 0 auto;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.breadcrumb-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  color: #14151f;
  font-family: "Poppins-Semibold";
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
}

.button-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.salary-button {
  color: #4950c7;
  font-size: 14px;
  font-family: "Poppins-Medium";
  line-height: 20px;
}

.content-container {
  padding-inline: 140px;
}

.content-inner {
  max-width: 1076px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 24px;
}
