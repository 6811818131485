.employee-paystubs-dA {
  width: 67.25rem;
  margin: 1rem auto;
}
.employee-paystubs-dA1 {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.employee-paystubs-dA1-Menu {
  color: var(--neutral-20, #292a3d);
  font-family: Poppins !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.employee-paystubs-dA1-submenu {
  font-family: Poppins-Regular !important;
  color: var(--neutral-20, #292a3d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 266.667% */
}
.employee-paystubs-dA1-btn {
  background: #3d43bb;
  border-radius: 42px;
  padding: 12px 24px;
  width: 155px;
  height: 44px;
  border: none;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: 14px;
}
.employee-paystubs-dA2 {
  /* border: 1px solid green; */
  margin-top: 20px;
  display: flex;
  /* justify-content: space-between; */
  gap: 30px;
}
.employee-paystubs-dA2-left {
  flex: 0.5;
  background: var(--gradient-light, linear-gradient(183deg, #ffefd3 0%, #e0e0ff 31.71%, #b4fff2 100%));
  border-radius: 24px;
  padding: 5px;
  display: flex;
  align-items: center;
}

.employee-paystubs-dA2-left img {
  padding: 0 0.5rem 0 1rem;
}

.employee-paystubs-dA2-congrats {
  font-family: Poppins-Medium !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
}
.employee-paystubs-dA2-right {
  flex: 0.5;
  background: linear-gradient(220.8deg, #ffffff 4.69%, #ffffff 41.39%, #ffffff 120.42%);
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 21px;
}
.employee-paystubs-dA2-companyname {
  font-family: Poppins-Medium !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
}
.employee-paystubs-dA2-type {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
}
.employee-paystubs-dA2-joindate {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #484649;
}
.employee-paystubs-dA2-contractbtn {
  font-family: Poppins-Regular !important;
  border: 1px solid #777680;
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  color: #4950c7;
  border-radius: 42px;
}

.employee-paystubs .common-grid-row {
  margin: 0 1rem;
}
