.folder-wrapper {
  width: 163px;
  cursor: pointer;
}

.folder-container {
  background: var(--color-surface-secondary);
  border-radius: var(--font-size-sm);
  display: flex;
  height: 112px;
  width: 163px;
  padding: 8px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    background: var(--color-surface-secondary-hover);
  }
}

.folder-icon {
  width: 48px !important;
  height: 48px !important;
  flex-shrink: 0;
  color: var(--color-icon-information-inverse);
}

.more-icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: var(--color-icon-information-inverse);
  position: absolute;
  top: 8px;
  right: 8px;
}

.folder-title {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.primary-info {
  padding-top: 2px;
  color: var(--color-text-highlight);
}

.secondary-info {
  padding-top: 2px;
  color: var(--color-text-body-primary);
}
