.leavetype-card-container {
  border-radius: 13px;
  background: linear-gradient(221deg, #ffefd3 4.69%, #e0e0ff 41.39%, #b4fff2 120.42%);
  padding: 15px 19px 15px 19px;
  width: 197px;
  min-width: 197px;
  height: 92px;
}

.leave-request-btn {
  border-radius: 21.5px;
  border: 1px solid var(--primary-100, #fff);
  background: #fff;
  height: fit-content;
  width: 69px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #14151f;
  font-family: Poppins-Regular;
  font-size: 13px;
  cursor: pointer;
}

.leave-count-request-wrapper {
  display: flex;
  justify-content: space-between;
}

.leave-count-flex-row {
  display: flex;
  flex-direction: row;
}

.leave-count {
  display: flex;
  flex-direction: column;
}

.leave-count-wrapper {
  display: flex;
  flex-direction: column;
}

.leave-count-remaining {
  color: #14151f;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.leave-count-total {
  color: #14151f;
  font-family: Poppins-Medium;
  font-size: 14px;
  font-style: normal;
  align-self: end;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.leave-card-type {
  color: #14151f;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 142.857% */
  letter-spacing: 0.4px;
  margin-top: 7px;
}

.leave-count-scheduled {
  margin-top: 7px;
  color: #06645c;
  font-family: Poppins-Regular;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.custom-chip.MuiChip-root {
  height: 22px !important;
}

.custom-chip .MuiChip-label {
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
  padding: 2px 8px;
}

.custom-chip .MuiChip-icon {
  margin-left: 8px;
  margin-right: 0px;
}
