.layout-container {
  width: 64.5rem;
  margin: 1.1rem auto;
}

.layout-heading {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.layout-subheading {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
