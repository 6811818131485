.main-title {
  font-family: Poppins !important;
  font-size: 24px !important;
  font-weight: 600;
  line-height: 44px;
  text-align: left;
}

.list-item {
  padding-left: 10px;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px;
  text-align: left;
  color: #666899;
}

.sub-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.employee-name {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: left;
}

.report-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.replacement-textarea {
  border-radius: 12px;
  width: 100%;
  height: 90px;
  background: white;
  font-family: Poppins-Regular;
  font-size: 16px;
  padding: 10px;
  margin-top: 1rem;
  border: 1px solid #b2b4e2;
  resize: none;
}

.replacement-textarea:focus {
  outline: none;
  border: 1px solid #141cdf;
}
