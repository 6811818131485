.ep-dash-company employee-card-avatar {
  width: 100px;
  height: 40px;
  object-fit: contain;
}
.ep-company-details {
  margin: auto;
  background: #ffffff;
  border-radius: 24px;
  padding: 15px;
  width: 910px;
  margin-top: 1.5rem;
}
.ep-company-details-d1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
}
.ep-company-details-d1a {
  font-family: Poppins-Medium !important;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 5px;
}
.ep-company-details-d1b {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.employee-company-web-link {
  display: flex;
  border: 1px solid #777680;
  align-items: center;
  border-radius: 22px;
  cursor: pointer;
  padding: 6px 10px 6px 18px;
  gap: 10px;
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.ep-company-details-d2 {
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  direction: row;
  direction: row;
  /* gap: 10px; */
  margin-top: 15px;
  margin-bottom: 15px;
}
.ep-company-details-d2a {
  display: flex;
  align-items: center;
  gap: 15px;
}
.ep-company-details-d2a img {
  border-radius: 100px;
  width: 63px;
  height: 63px;
}
.ep-company-details-d2b {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #7c7eb6;
}
.ep-company-details-d2c {
  font-family: Poppins-Medium !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.ep-company-details-d2d {
  font-family: Poppins-Medium !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.ep-company-details-d3 {
  padding: 10px;
}
.ep-company-details-d3a {
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 10px;
}

.ep-company-details-d3-title-value {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.ep-company-details-d3-title {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #666899;
}
.ep-company-details-d3-value {
  font-family: Poppins-Medium !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}
