.pending-leaves-container-history {
  border-radius: 12px;
  background: #fbfaff;
  display: flex;
  flex-direction: column !important;
  width: 100%;
  gap: 1rem !important;
  padding: 1rem 3.5rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
}
