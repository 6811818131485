.overview-tab-icons {
  width: 48px;
  height: 48px;
  clip-path: circle();
  display: grid;
  place-items: center;
}

.success-tick-icon {
  @extend .overview-tab-icons;
  width: 48px;
  height: 48px;
  background: var(--color-surface-success);
  clip-path: circle();
  display: grid;
  place-items: center;
}

.success-tick-icon svg {
  fill: var(--color-icon-success);
}

.failure-warning-icon svg {
  fill: var(--color-icon-danger);
}

.employee-success-name {
  color: var(--color-text-highlight);
}

.failure-warning-icon {
  @extend .overview-tab-icons;
  background: var(--color-surface-danger);
}
