@import "styles/Colors.scss";

.valid-color {
    color: var(--color-primitives-success-800)
}
.invalid-color {
    color: var(--color-text-danger)
}
.disabled-color {
    color: var(--color-text-body-primary)
}

.invalid-color-icon {
    color: var(--color-icon-success);
}
.invalid-color-icon {
    color: var(--color-icon-danger);
}
.disabled-color-icon {
    color: var(--color-icon-secondary);
}   