.dropzone {
  margin: auto;
  background: rgba(252, 251, 255, 1);
}

.droparea {
  margin: 0.5rem;
  padding: 0.75rem;
  border: 1px solid rgba(224, 224, 255, 1);
  background: white;
  border-radius: 12px;
  cursor: pointer;
}

.uploaded-file-list-card {
  background: white;
  border: 1px solid rgba(224, 224, 255, 1);
  border-radius: 12px;
  margin: 0.5rem 1rem;
  padding: 0.5rem;
}
