@import "styles/Typography.scss";
@import "styles/Colors.scss";

.uploader-container {
  margin: 0 auto;
  text-align: center;
}

.uploader-drop-area {
  border-radius: 4px;
  border: 1px dashed var(--color-border-neutral);
  padding: 20px;
  cursor: pointer;
  transition: border-color 0.3s;
}

.uploader-drop-area:not(.uploader-drop-area-failure):hover {
  border-radius: 4px;
  border: 1px dashed var(--color-border-primary-hover);
  background-color: var(--color-brand-primary-blue-50);
}

.uploader-drop-area-failure {
  border-radius: 4px;
  border: 1px solid var(--color-border-danger) !important;
  background: var(--color-primitives-danger-50);
}

.uploader-drop-area-failure .uploader-icon {
  color: var(--color-primitives-danger-600);
}

.uploader-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploader-icon {
  color: var(--color-surface-action-primary);
}

.uploader-text {
  @extend .para-body-l-regular;
  margin: 8px 0;
  color: var(--color-text-body-secondary);
}

.uploader-text a {
  @extend .para-body-l-medium;
  color: var(--color-surface-action-primary) !important;
}

.uploader-text span {
  margin-left: -5px;
}

.uploader-subtext {
  @extend .para-body-m-regular;
  color: var(--color-text-body-primary);
  text-align: center;
}

.file-name-success {
  @extend .para-body-l-regular;
  color: var(--color-text-body-secondary);
}

.file-name-error {
  @extend .para-body-l-regular;
  color: var(--color-text-danger) !important;
}

.file-size {
  color: var(--color-text-body-primary);
  @extend .para-body-m-regular;
}

.progress {
  height: 100%;
  background-color: var(--color-text-body-primary);
}

.custom-linear-progress {
  width: 200px;
  border-radius: 3px !important;
}

.progress-bar-complete {
  background-color: var(--color-surface-action-primary) !important;
}

.progress-bar-error {
  background-color: var(--color-primitives-danger-600) !important;
  opacity: 0.6 !important;
}

.progress-root-error {
  background-color: var(--color-primitives-danger-600) !important;
  opacity: 0.4 !important;
}

.file-remove {
  cursor: pointer;
  color: var(--color-brand-primary-grey-400);
  margin-top: 13px;
  margin-right: 5px;
}

.file-info {
  @extend .para-body-m-regular;
}

.file-info-error {
  color: var(--color-text-danger);
  @extend .para-body-m-regular;
}

.file-status-error {
  color: var(--color-text-danger);
  @extend .para-body-m-medium;
}

.uploader-files {
  display: flex;
  flex-direction: column;
  text-align: left;
  max-height: 260px;
  overflow: scroll;
}

.uploader-files-border {
  border-radius: 8px;
  border: 1px solid var(--color-border-primary, #dfdfdf);
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.file-icon {
  padding-right: 25px;
  color: var(--color-primitives-primary-600);
}

.file-icon-red {
  padding-right: 25px;
  color: var(--color-surface-action-danger);
}

.file-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  width: 70%;
}

.file-size-status {
  @extend .para-body-m-regular;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;
  color: var(--color-text-body-primary);
}

.progress {
  height: 4px;
}

.file-size {
  margin-right: 4px;
}

.dot-span {
  padding: 0 4px 0 4px;
}

.file-info-complete {
  margin-left: 4px;
  @extend .para-body-m-regular;
  color: var(--color-text-body-primary);
}

.upload-button {
  @extend .para-body-l-medium;
  color: var(--color-text-highlight);
  border: none;
  text-decoration: underline;
  background: none;
  cursor: pointer;
}

.check-circle {
  color: var(--color-icon-success);
  font-size: 24px;
}

.uploader-drop-area-failure .uploader-subtext {
  @extend .para-body-m-regular;
  color: var(--color-primitives-danger-800);
  text-align: center;
}
