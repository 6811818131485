.no-salary-icon {
  width: 64px !important;
  height: 64px !important;
  color: var(--color-brand-primary-grey-400);
}

.salary-info-container {
  border-bottom: 1px solid var(--color-border-primary);
}

.salary-info {
  display: flex;
  gap: 40px;
}

.salary-info-discounted {
  display: flex;
  gap: 30px;
}

.salary-item {
  min-width: 128px;
}

.salary-label {
  font-size: 14px;
  color: var(--color-brand-primary-grey-600);
  margin-top: 4px;
}

.pay-slips-container {
  padding: 24px;
  background-color: var(--color-primitives-white);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.payslip-download-icon {
  color: var(--color-brand-primary-grey-400);
}

.pay-slips-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-primitives-black-black);
  margin: 0;
}

.payslip-filter {
  .dropdown-button {
    min-width: 90px;
  }

  .dropdown-list {
    background-color: var(--color-primitives-white-white) !important;
    border-radius: 4px;
  }

  .dropdown-item {
    border-radius: 0 !important;
  }
}

.form-row {
  &.two-columns {
    display: flex;
    gap: 16px;

    > * {
      flex: 1;
    }
  }

  &.three-columns {
    display: flex;
    gap: 16px;

    > * {
      flex: 1;
    }
  }

  .money-icon {
    color: var(--color-brand-primary-grey-400);
    width: 24px;
    height: 24px;
  }
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-block: 8px;
  gap: 8px;
}

.assignment-banner-bg {
  background-color: var(--color-primitives-warning-50);
  border-radius: var(--border-radius-md);
  .info-icon {
    height: 24px;
    width: 24px;
    color: var(--color-primitives-warning-600);
  }
}

.receipt-icon {
  width: 64px !important;
  height: 64px !important;
  color: var(--color-brand-primary-grey-400);
}

.discount-info-container {
  margin-top: 28px;
}
