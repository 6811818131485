.info-container {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  border: 1px solid var(--color-border-primary, #dfdfdf);
}

.no-bottom-border {
  border-bottom: none;
}

.border-radius-single {
  border-radius: 12px;
}

.border-radius-top {
  border-radius: 12px 12px 0 0;
}

.border-radius-middle {
  border-radius: 0;
}

.border-radius-bottom {
  border-radius: 0 0 12px 12px;
}

.info-secondary-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--color-text-action);
  padding: 4px var(--font-size-xs, 10px);
  border-radius: var(--border-radius-full, 999px);
  background: var(--color-surface-action-secondary);
}

.info-primary-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--color-text-on-action-white);
  padding: 4px var(--font-size-xs, 10px);
  border-radius: var(--border-radius-full, 999px);
  background: var(--color-surface-action-primary);
}

.info-label-text {
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: var(--font-size-xs, 10px);
  color: var(--color-text-body-primary);
  border-radius: 100px;
  background: var(--color-surface-primary-pressed);
}

.info-edit-icon {
  cursor: pointer;
  color: var(--color-icon-secondary);
  height: 24px;
  width: 24px;
}
