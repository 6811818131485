@import url("styles/Colors.scss");
@import url("styles/Typography.scss");

.slot-span {
  display: flex;
}

.left-slot {
  margin-right: 8px !important;
}

.right-slot {
  margin-left: 8px !important;
}

.menu-item {
  font-family: var(--font-family-inter) !important;
  color: var(--color-text-body-secondary) !important;
}

.danger-menu-item {
  color: var(--color-text-danger) !important;
}

.disabled-menu-item {
  background: none !important;
  color: var(--color-text-disabled) !important;
}

.menu-item:hover {
  background: var(--color-surface-primary-hover) !important;
}

.Mui-selected {
  background: var(--color-surface-primary-pressed) !important;
}

.small-menu-list-item {
  height: 36px !important;
}

.large-menu-list-item {
  height: 48px !important;
}
