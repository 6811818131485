@font-face {
  font-family: "Cairo-Medium";
  src:
    local("Cairo"),
    url("./../public/assets/fonts/static/Cairo-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src:
    local("Poppins-Light"),
    url("./../public/assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src:
    local("Poppins-Regular"),
    url("./../public/assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src:
    local("Poppins-Medium"),
    url("./../public/assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src:
    local("Poppins-SemiBold"),
    url("./../public/assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src:
    local("Poppins-Bold"),
    url("./../public/assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-ExtraBold";
  src:
    local("Cairo-ExtraBold"),
    url("./../public/assets/fonts/static/Cairo-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-Regular";
  src:
    local("Cairo-Regular"),
    url("./../public/assets/fonts/static/Cairo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-Bold";
  src:
    local("Cairo-Bold"),
    url("./../public/assets/fonts/static/Cairo-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Cairo-SemiBold";
  src:
    local("Cairo-SemiBold"),
    url("./../public/assets/fonts/static/Cairo-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans";
  src:
    local("OpenSans"),
    url("./../public/assets/fonts/OpenSans.ttf") format("truetype");
}

@font-face {
  font-family: "OpenSans-Bold";
  src:
    local("OpenSans-Bold"),
    url("./../public/assets/fonts/OpenSans-ExtraBold.ttf") format("truetype");
}

* {
  font-family: "Poppins-Medium";
}

p {
  margin: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: "#D9D9D9";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiTabs-indicator {
  height: 3px !important;
  border-radius: 4px !important;
}

.custom-scroll {
  max-height: 88%;
  overflow-y: scroll;
}
.custom-scroll::-webkit-scrollbar {
  width: 4px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: #bfc2ff;
  opacity: 0.3;
  width: 4px;
  border-radius: 20px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #7d84fe;
  border-radius: 20px;
  width: 4px;
  border: 0px solid transparent;
  background-clip: content-box;
  min-height: 46px;
  max-height: 46px;
  opacity: 1;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #7d84fe;
  opacity: 0.3;
}
