.chip {
  transition: all 0.3s ease;

  &:focus {
    outline: none;
  }
}

.chip-small {
  height: 24px !important;
  padding: 3px 4px !important;

  .MuiChip-icon {
    height: 18px !important;
    width: 18px !important;
  }

  .chip-delete-icon {
    height: 16px !important;
    width: 16px !important;
  }
}

.chip-medium {
  height: 32px !important;
  padding: 4px !important;

  .MuiChip-icon {
    height: 24px !important;
    width: 24px !important;
  }

  .chip-delete-icon {
    height: 20px !important;
    width: 20px !important;
  }
}

@mixin chip-styles($text, $bg, $hover-bg, $focus-border, $thumbnail, $delete-icon, $outline-border) {
  color: $text !important;
  background-color: $bg !important;

  .MuiChip-icon {
    color: $text !important;
  }

  .chip-delete-icon {
    color: $delete-icon !important;
    opacity: 0.7;
  }

  &:hover {
    background-color: $hover-bg !important;
  }

  &:focus {
    background-color: $hover-bg !important;
    border: 1px solid $focus-border !important;
  }

  &.MuiChip-outlined {
    background-color: var(--color-primitives-white-white) !important;
    border-color: $outline-border !important;

    &:hover {
      background-color: $bg !important;
    }

    &:focus {
      background-color: $hover-bg !important;
      border-color: $focus-border !important;
    }
  }
}

.chip-filled-warning,
.chip-outlined-warning {
  @include chip-styles(
    var(--color-chip-warning-text),
    var(--color-chip-warning-bg),
    var(--color-chip-warning-hover),
    var(--color-chip-warning-focus-border),
    var(--color-chip-warning-thumbnail),
    var(--color-chip-warning-delete),
    var(--color-chip-warning-outline-border)
  );
}

.chip-filled-default,
.chip-outlined-default {
  @include chip-styles(
    var(--color-chip-default-text),
    var(--color-chip-default-bg),
    var(--color-chip-default-hover),
    var(--color-chip-default-focus-border),
    var(--color-chip-default-thumbnail),
    var(--color-chip-default-delete),
    var(--color-chip-default-outline-border)
  );
}

.chip-filled-success,
.chip-outlined-success {
  @include chip-styles(
    var(--color-chip-success-text),
    var(--color-chip-success-bg),
    var(--color-chip-success-hover),
    var(--color-chip-success-focus-border),
    var(--color-chip-success-thumbnail),
    var(--color-chip-success-delete),
    var(--color-chip-success-outline-border)
  );
}

.chip-filled-error,
.chip-outlined-error {
  @include chip-styles(
    var(--color-chip-error-text),
    var(--color-chip-error-bg),
    var(--color-chip-error-hover),
    var(--color-chip-error-focus-border),
    var(--color-chip-error-thumbnail),
    var(--color-chip-error-delete),
    var(--color-chip-error-outline-border)
  );
}

.chip-filled-info,
.chip-outlined-info {
  @include chip-styles(
    var(--color-chip-info-text),
    var(--color-chip-info-bg),
    var(--color-chip-info-hover),
    var(--color-chip-info-focus-border),
    var(--color-chip-info-thumbnail),
    var(--color-chip-info-delete),
    var(--color-chip-info-outline-border)
  );
}

.chip-filled-primary,
.chip-outlined-primary {
  @include chip-styles(
    var(--color-chip-primary-text),
    var(--color-chip-primary-bg),
    var(--color-chip-primary-hover),
    var(--color-chip-primary-focus-border),
    var(--color-chip-primary-thumbnail),
    var(--color-chip-primary-delete),
    var(--color-chip-primary-outline-border)
  );
}

.chip-filled-secondary,
.chip-outlined-secondary {
  @include chip-styles(
    var(--color-chip-secondary-text),
    var(--color-chip-secondary-bg),
    var(--color-chip-secondary-hover),
    var(--color-chip-secondary-focus-border),
    var(--color-chip-secondary-thumbnail),
    var(--color-chip-secondary-delete),
    var(--color-chip-secondary-outline-border)
  );
}

.chip-disabled {
  opacity: 0.38;
  pointer-events: none;
}

.chip-filled-warning.chip-disabled,
.chip-outlined-warning.chip-disabled {
  color: var(--color-primitives-white-white) !important;
}

.chip-filled-default.chip-disabled,
.chip-outlined-default.chip-disabled {
  color: var(--color-primitives-black-black) !important;
}

.chip-filled-success.chip-disabled,
.chip-outlined-success.chip-disabled {
  color: var(--color-primitives-success-800) !important;
}

.chip-filled-error.chip-disabled,
.chip-outlined-error.chip-disabled {
  color: var(--color-primitives-danger-800) !important;
}

.chip-filled-info.chip-disabled,
.chip-outlined-info.chip-disabled {
  color: var(--color-primitives-white-white) !important;
}

.chip-filled-primary.chip-disabled,
.chip-outlined-primary.chip-disabled,
.chip-filled-secondary.chip-disabled,
.chip-outlined-secondary.chip-disabled {
  color: var(--color-brand-primary-blue-600) !important;
}
