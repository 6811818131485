@import "styles/Typography.scss";
@import "styles/Colors.scss";

.main-border {
    border-radius: var(--border-radius-sm);
    border: 1px solid var(--color-border-primary);
}

.main-div {
    padding: var(--font-line-height-3xl) 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-employee-sub-heading{
    color: var(--color-text-body-primary);
}