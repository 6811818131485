.button-container {
  display: flex;
  gap: 10px;
}

.button {
  padding: 10px 24px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

.accept {
  background-color: #D9FFED;
  color: #00793f;
  transition: background-color 0.5s;
}

.accept:hover {
  background-color: #a6ffd4 !important;
  box-shadow: none !important;
}

.reject {
  background-color: #ffdad6;
  color: #ba1a1a;
  transition: background-color 0.5s;
}
.reject:hover {
  background-color: #FFABA3 !important;
  box-shadow: none !important;
}

.button:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
