@import "styles/Typography.scss";
@import "styles/Colors.scss";

.custom-tabs {
  .MuiTabs-indicator {
    background-color: var(--color-text-action) !important;
  }
}

.custom-tab {
  &.MuiTab-root {
    color: var(--color-text-body-primary);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    padding: 9px 16px;

    &.Mui-disabled {
      color: var(--color-text-disabled);
    }
    &.Mui-selected {
      color: var(--color-text-action);
      background-color: transparent !important;
    }
  }
}
