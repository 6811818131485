.custom-grid-container {
  background-color: #f5f5fd;
  min-width: 202px;
  width: 202px;
  display: flex;
  padding: 20px 16px !important;
  flex-direction: column;
  border-radius: 24px;
  border: 1px solid #cbccef;
  align-items: center;
}

.image-container {
  position: relative;
  display: inline-block;
}

.custom-card-media {
  width: 170px !important;
  height: 160px !important;
  border: 5px solid #fff !important;
  border-radius: 24px !important;
  overflow: hidden !important;
  box-sizing: border-box !important;
}

.icon-overlay {
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-status-container {
  background: white;
  border-radius: 17px;
  width: max-content;
  padding: 2px 8px;
  gap: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cbccef;
}

.custom-section-container {
  display: flex;
  flex-direction: column;
}

.pb-2p {
  padding-bottom: 2px;
}

.pt-2p {
  padding-top: 2px;
}

.custom-section-container .section-child:first-child {
  border-bottom: 1px solid #cbccef;
}

.custom-section-container .section-child {
  border-bottom: 1px solid #cbccef;
  padding-top: 8px;
  padding-bottom: 8px;
}

.custom-section-container .section-child:last-child {
  border-bottom: none;
  padding-top: 8px;
  padding-bottom: 0px;
}
