.ep-payroll-stubs {
  background: #ffffff;
  border-radius: 24px;
  width: 90%;
  margin: 0px auto;
  padding-bottom: 15px;
}
.ep-payroll-stubs-h1 {
  padding: 22px 23px;
  font-family: Poppins-Medium !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  /* margin-right: 85px; */
}
.ep-payroll-stubs-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}
.ep-payroll-stubs-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.ep-payroll-stubs-month {
  font-family: Poppins-Regular !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
}
.ep-payroll-stubs-date {
  font-family: Poppins-Regular !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #918f9a;
  margin-top: 6px;
}

@media only screen and (min-width: 900px) {
  .ep-payroll-stubs {
    width: 58%;
  }
}
