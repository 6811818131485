.pending-leave-reason {
  color: #3d43bb;
  font-family: Poppins-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 19.5px;
}

.pending-leave-date {
  color: #292a3d;
  font-family: Poppins-SemiBold;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.pending-leave-arrow {
  transform: rotate(180deg);
}

.pending-leaves-date {
  display: flex;
  /* padding: 0.7rem 0; */
}

.pending-leaves-time-icon {
  padding: 0.5rem 1rem;
}

.chip-leaves-pending-label {
  color: #3d43bb;
  font-family: Poppins-Medium;
  font-size: 15px;
}
.cancel_leave_request_chip_label {
  color: #3d43bb;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 20px;
}

.chip-leaves-pending-icon {
  padding-left: 0.5rem;
  transform: scale(1.3);
}

.pending-leaves-top-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;
  margin-top: 11px;
}

.pending-leave-chip {
  align-self: center;
  margin-right: 1rem !important;
}

.pending-timeoff-details {
  display: flex;
  margin-left: 1rem;
}

.pending-timeoff-text-emp {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 12px;
  width: 20%;
  margin-block: 5px;
}

.pending-leave-chip-status {
  background: #ffefd3 !important;
  border: 1px solid #ffdf9c !important;
  color: #3f2e00 !important;
}

.pending-leave-chip-status-icon {
  transform: scale(0.6);
  filter: invert(13%) sepia(88%) saturate(1844%) hue-rotate(41deg) brightness(100%) contrast(106%);
  margin-left: -1px !important;
  margin-top: 1px;
}

.pending-timeoff-comments {
  color: #292a3d;
  font-family: Poppins-Medium;
  font-size: 14px;
  padding: 0 16px;
  margin-top: 7px;
  margin-bottom: 12px;
  margin-right: 2rem;
}

.pending-timeoff-comments-img {
  clip-path: circle();
  width: 4rem;
  padding: 1rem;
}

.pending-timeoff-comment-wrapper {
  background: #f8f6ff;
  border-radius: 12px;
  margin-right: 0.5rem;
  width: 100%;
  min-height: 45px;
}

.pending-timeoff-no-comment-wrapper {
  display: flex;
  padding: 7px 14px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #f1efff;
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
}

.pending-timeoff-comment {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 14px;
  padding: 0.6rem 1rem;
}

.pending-timeoff-comment-author {
  color: #c7c5d0;
  font-family: Poppins-Regular;
  font-size: 11px;
  padding-left: 1rem;
}

.pending-timeoff-comments-container {
  display: flex;
  align-items: start;
}

.pending-timeoff-request-date {
  color: #7f7e82;
  font-family: Poppins-Regular;
  font-size: 12px;
  padding: 19px 0.5rem;
}

.medical-docs-btn {
  background: #f8f6ff;
  border-radius: 12px;
  margin-right: 1.5rem;
  padding: 0 0.5rem;
}

.medical-docs-btn-label {
  text-wrap: nowrap;
}
