@import "styles/Typography.scss";
@import "styles/Colors.scss";

.custom-card {
  padding: 24px;
}

.custom-header-root {
  min-width: -webkit-fill-available;
  padding: 12px !important;
}

.custom-header-title-lg {
  color: var(--color-text-headings-primary);
  padding: 4px 0px 4px 0px;
  font-family: var(--font-family-heading) !important;
  font-size: var(--font-size-3xl) !important;
  font-style: normal !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: var(--line-height-xxxxl) !important;
}

.custom-header-title-sm {
  color: var(--color-text-headings-primary);
  font-family: var(--font-family-heading) !important;
  font-size: var(--font-size-md) !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: var(--line-height-md) !important;
}

.custom-header-subheader {
  color: var(--color-text-body-primary) !important;
  font-family: var(--font-family-heading) !important;
  font-size: var(--font-size-md) !important;
  font-weight: var(--font-weight-regular) !important;
  line-height: var(--line-height-md) !important;
}
.custom-header-avatar {
  width: 40px;
  height: 40px;
  margin-right: 16px;
  align-items: center;
  justify-content: center;
}

.text-disabled {
  color: var(--color-text-disabled) !important;
}

.custom-header-action {
  height: 24px !important;
  display: flex;
  align-items: center;
  align-self: auto !important;
  margin-right: 0 !important;

  .MuiSvgIcon-root {
    font-size: 24px !important;
    width: 24px !important;
    height: 24px !important;
  }
}

.custom-card-content {
  padding-left: 16px;
  padding-right: 16px;
}

.card-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
