.container-header {
  margin: 0;
  padding: 0.5rem 1rem;
}

.section-heading {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 3px;
  letter-spacing: 0.5px;
}

.section-subheading {
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
