.employee-time-off-table-header {
  padding: 5px 17px 5px 11px;
  justify-content: space-between;
  align-items: center;
  border-radius: 24px;
  border-bottom: 0.5px solid var(--neutral-95, #ececfb);
  background: var(--primary-99, #fbfaff);
}

.employee-time-off-table-header-label {
  color: var(--neutral-50, #666899) !important;
  font-family: Poppins-Medium !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.employee-time-off-date-range {
  color: var(--neutral-20, #292a3d);
  font-family: Poppins-Medium !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal !important;
}

.employee-time-off-request-date-or-leave-cell {
  color: var(--neutral-20, #292a3d);
  font-family: Poppins-Medium !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}

.employee-time-off-used {
  /* color: var(--neutral-20, #292A3D); */
  font-family: Poppins-Medium !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  border-radius: 13px;
  border: 1px solid var(--neutral-varian-90, #e4e1ec);
  display: flex;
  width: 107px;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.employee-time-off-initiated {
  /* color: var(--neutral-20, #292A3D); */
  font-family: Poppins-Regular !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  border-radius: 13px;
  border: 1px solid var(--neutral-varian-90, #bfc2ff);
  display: flex;
  width: 107px;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #02006e;
  background: #f1efff;
}

.employee-time-off-view-details {
  color: var(--primary-10, #02006e);
  font-family: Poppins-Medium;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  cursor: pointer;
}

.employee-time-off-hr-docs-review {
  display: flex;
  width: 107px;
  padding: 1px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  font-size: 12px !important;
  border-radius: 13px;
  border: 1px solid #cecece;
  min-width: 107px;
  font-family: Poppins-Medium !important;

  background: #cecece;
}

.employee-time-off-status {
  border-radius: 13px;
  border: 1px solid var(--secondary-90, #ffdf9c);
  background: var(--secondary-95, #ffefd3);
  display: flex;
  width: 107px;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  font-family: Poppins-Medium !important;
  color: var(--secondary-20, #3f2e00);
  min-width: 130px;
}

.edit-leave-type-taken {
  height: 63px !important;
  border-radius: 12px;
  background: var(--primary-100, #fff);
  flex-shrink: 0;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
  width: 232px;
  height: 80px;
  padding-left: 19px;
  padding-right: 19px;
  border: 1px dashed #d9daf2;
}

.tabs-container {
  width: 100%;
  position: relative;
  /* padding-left: 50px; */
  overflow-x: scroll;
}
.request-new-leave-title {
  font-family: Poppins-Medium;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 14px;
}

.leave-taken-select-type {
  color: var(--neutral-20, #292a3d);
  font-family: Poppins-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 114.286% */
  text-align: left;
  margin-top: 24px;
  margin-bottom: 10px;
  padding-left: 2rem;
  letter-spacing: 0.1px;
}

.leave-taken-off-taken {
  color: #292a3d;
  font-family: Poppins-Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  padding-left: 18px;
}

.leave-taken-off-taken-description {
  color: var(--neutral-20, #292a3d);

  font-family: Poppins-Medium;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 16px;
  padding-left: 18px;
}

.employee-taken-leaves-count {
  text-align: center;
  display: flex;
  width: 79px;
  height: 35px;
  padding: 7.963px 11.148px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12.741px;
  border-radius: 27px;
  border: 1px solid var(--primary-90, #e0e0ff);
  font-size: 24px;
  font-weight: 600;
}

.leave-taken-reson {
  display: flex;
  width: 300px;
  height: 73px;
  padding: 9px 6px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 12px;
  margin-left: 2rem;
  border: 1px solid var(--neutral-90, #d9daf2);
  font-size: 14px;
  line-height: 19px;
  font-family: Poppins-Medium;
  resize: none;
}

.allowed-timeoff {
  width: 153px;
  height: 32px;
  padding: 6px 11px 6px 11px;
  border-radius: 29px;
  gap: 138px;
  font-family: Poppins-Medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;

  background: #ffc200;
  display: flex;
  align-items: center;
  justify-content: center;
}

.req-leave-taken-reson {
  display: flex;
  height: 53px;
  padding: 9px 6px;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid var(--neutral-90, #d9daf2);
  font-size: 14px;
  line-height: 19px;
  font-family: Poppins-Medium;
  resize: none;
  /* background-color:  #D9DAF2; */
  background: #fbfaff;

  margin-right: 26px !important;
}

.add-swaped-leaves-title {
  font-family: Poppins-Medium;
  font-size: 20px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 18px;
}
.swapped-days {
  height: 22px;
  padding: 3px 13px 3px 13px;
  border-radius: 40px;
  gap: 8px;
  background: #f1efff;
  margin-left: 18px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins-Medium;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
}

.save-buttontext {
  font-family: Poppins-Medium;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-align: left;
  color: #ffffff;
}

.add-timeoff-req {
  font-family: Poppins-Medium;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0px;
  /* text-align: left; */
}

.timeoff-view {
  padding: 7px 60px;
  overflow-y: hidden;
  max-height: calc(100vh - 260px);
}
