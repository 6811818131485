@import "styles/Colors.scss";

.add-talent-container {
  padding: 16px 24px;
  background: var(--color-surface-primary);
  width: 900px;
  margin: 24px auto;
  border-radius: 8px;
}

.add-talent-btn {
  width: 900px !important;
  margin: auto !important;
}

.add-talent-wrapper {
  margin-bottom: 42px;
}
