.request-date-spacing {
  padding: 0px 0px 10px 16px;
  padding-top: 0;
}

.scheduled-leaves-top-section {
  display: flex;
  justify-content: space-between;
}

.leaves-top-section {
  display: flex;
  justify-content: end;
}

.collapsed-customer-portal .pending-timeoff-details {
  margin-top: 5px;
}
