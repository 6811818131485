.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-icon {
  margin-left: 8px;
  width: 12px;
  height: 12px;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1000;
  background: transparent;
  border: none;
}

.defined-range-wrapper {
  padding: 16px;
  background: white;
}

.date-range-picker-wrapper .rdrDateRangeWrapper {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 16px 32px 0px rgba(23, 37, 76, 0.12);
}

.date-range-picker-wrapper .rdrDefinedRangesWrapper {
  display: block;
  width: 151px;
  background-color: #fff;
  z-index: 1;
}

.date-range-picker-wrapper .rdrInputRanges {
  display: none;
}

.rdrStaticRange {
  background-color: #fff;
  z-index: 1;
  border-bottom: none;
}

.rdrDefinedRangesWrapper {
  padding-top: 25px;
}

.rdrStaticRangeLabel {
  color: #293050;
  font-variant-numeric: lining-nums proportional-nums stacked-fractions;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
}

/* .rdrStaticRangeSelected span {
  color: red;
} */
