.leaves-holidays-container {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
}

.leaves-holidays-card-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 12px;
  border: 1px solid #e0e0ff;
  background: #fff;
  padding: 0.5rem 0.9rem;
}

.timeoff-used-label {
  float: left;
  /* width: 60%; */
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 14px;
  margin-right: 1rem;
  align-self: center;
}

.timeoff-used-value {
  float: left;
  /* width: 30%; */
  color: #292a3d;
  font-family: Poppins-Regular;
  font-size: 14px;
  border-radius: 20px;
  border: 1px solid #e4e1ec;
  padding: 0.15rem 0.5rem;
}

.timeoff-used-wrapper {
  float: left;
  width: 29%;
  display: flex;
  align-items: center;
  justify-content: end;
}

.leaves-holidays-arrow-icon {
  clip-path: circle();
  padding: 0.75rem;
}

.leaves-holidays-arrow-right {
  transform: rotate(180deg);
}
