.action-icon-color {
  color: var(--color-icon-secondary) !important;
}

.action-icon-size:disabled {
  color: var(--color-surface-action-primary-disabled) !important;
}

.users-icons-size,
.action-icons-size {
  width: 24px !important;
  height: 24px !important;
}

.user-list-container {
  padding: 15px;
}
