/* Daterange.css */
.react-date-range-calendar .rdrWeekDay.rdrDayIsWeekend {
  color: gray;
  pointer-events: none;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrStartEdge {
  color: rgb(19, 21, 153) !important;
}

.rdrEndEdge {
  color: #636ae2 !important;
}

.rdrInRange {
  color: #f1efff !important;
}

.rdrDay .rdrInRange {
  border-radius: 3px !important;
}

.rdrDay .rdrEndEdge {
  border-radius: 3px !important;
}

.rdrDay .rdrStartEdge {
  border-radius: 3px !important;
}

.rdrDayNumber {
  color: black !important;
}

.rdrDay .rdrDayStartPreview + .rdrDayNumber span {
  color: white !important;
}

.rdrDayInPreview {
  border: none !important;
  background: #f1efff !important;
  color: black !important;
  z-index: 0 !important;
}

.rdrDayHovered .rdrDayStartPreview {
  background: #636ae2 !important;
  color: white !important;
  z-index: 0 !important;
  border: none !important;
  border-radius: 3px;
}

.rdrDayStartPreview {
  background: rgb(19, 21, 153) !important;
  color: white !important;
  z-index: 0 !important;
  border: none !important;
  border-radius: 3px;
}

.rdrDayEndPreview {
  background: #636ae2 !important;
  color: white !important;
  z-index: 0 !important;
  border: none !important;
  border-radius: 3px;
}

.rdrDayHovered span span {
  color: white !important;
}

.rdrDayHovered {
  border: none !important;
}

.rdrDayWeekend {
  pointer-events: none !important;
}

.rdrDayWeekend span {
  color: #d5dce0;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: black;
}

.rdrMonth {
  border-right: 1px dashed lightgrey;
}

.rdrMonth:last-child {
  border-right: none;
}

.rdrDateDisplayWrapper,
.rdrMonthName {
  display: none;
}
