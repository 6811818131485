.admin-notes-container {
  background-color: #f5f5ff;
}

.admin-notes-wrapper {
  width: 555px;
  margin: 0 auto;
  padding-top: 20px;
}

.notes-textarea {
  border-radius: 4px;
  width: 100%;
  height: 10em;
  background: white;
  font-family: Poppins-Regular;
  font-size: 16px;
  padding: 10px;
  margin-top: 1rem;
  border: 1px solid lightgray;
  resize: none;
}

.priority-example {
  color: black;
  text-transform: capitalize;
}

.priority-list {
  font-weight: 300;
  font-size: 12px !important;
  padding-top: 12px !important;
  color: gray;
  width: 555px;
}

.notes-textarea:focus {
  outline: none;
  border: 1px solid gray;
}

.right-arrow {
  filter: invert(100%);
  transform: rotate(180deg);
  scale: 0.7;
}

.notes-empty-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 195px;
  margin-top: 195px;
}

.notes-empty-card-font {
  font-size: 12px;
  color: gray;
}

#notes-priority {
  padding-top: 30px;
}
