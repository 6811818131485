.create-payoneer-account-card {
  background: linear-gradient(
    220.8deg, 
    rgba(255, 239, 211, 0.3) 4.69%, 
    rgba(224, 224, 255, 0.3) 41.39%, 
    rgba(180, 255, 242, 0.3) 120.42%
  );
}

.payoneer-pending-leave-chip-status-icon-review {
  transform: scale(0.6);
  width: 25px;
  height: 25px;
}
.payoneer-pending-leave-chip-status-review {
  font-size: 12px;
  background: #ffefd3 !important;
}
.payoneer-pending-leave-chip-status-review-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: -4px;
}
.payoneer-pending-leave-chip-status-review-image {
  height: 15px;
  width: 15px;
}
.payoneer-pending-leave-chip-status-review-image-text {
  font-weight: 400;
  font-family: "Poppins";
  margin-left: 4px;
}
.payoneer-pending-leave-chip-status-icon-active-label {
  font-size: 12px;
  color: #157b32;
  display: flex;
  justify-content: space-between;
}
.payoneer-pending-leave-chip-status-active {
  background: #ddfce6 !important;
}
.payoneer-pending-leave-chip-status-icon-error-label {
  font-size: 12px;
  color: #690005;
  display: flex;
  justify-content: space-between;
}
.payoneer-pending-leave-chip-status-error {
  height: 22px;
  background: #ffdad6 !important;
}
.payoneer-label-go-to-payoneer {
  color: #02006e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 135px;
}
.payoneer-label-balance-icons {
  margin-left: -6px;
  color: #02006e;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payoneer-btn-text {
  font-size: 14px;
  font-family: "Poppins";
}
.payoneer-go-to-payoneer-arrow-left {
  padding-left: 0.5rem;
  transform: scale(1.3);
  margin-right: -10px !important;
}
