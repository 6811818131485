.unassigned-employee-container {
  display: grid;
  place-items: center;
  gap: 8px;
}

.unassigned-employee-container svg {
  color: var(--color-icon-secondary);
  width: 64px;
  height: 64px;
}

.admin-overview-heading-icon {
  width: 20px;
  height: 20px;
}

.no-screen-wrapper {
  border-radius: 4px;
  padding-top: 11px;
  border: 1px solid #dfdfdf;
}

.no-screen-inactive-icon {
  display: flex;
  width: 36px;
  height: 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 77px;
  background-color: var(--color-brand-primary-grey-50) !important;
}
