.pending-leaves-container-customer {
  border-radius: 12px;
  background: #fbfaff;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
  border: 1px solid hsl(236deg 100% 94% / 50%);
  padding: 1.75rem 3rem;
}

.blurred-arrow {
  opacity: 0.5;
  cursor: not-allowed;
}

.count-text {
  font-size: 13px;
  font-weight: 600;
  line-height: 32px;
  height: 29px;
  letter-spacing: 0em;
  text-align: center;
  width: 53px;
  border-radius: 27px;
  border: 1px solid #bfc2ff;
  font-family: Poppins-Regular;
}

.bg-icon {
  background: #f1efff;
}

.arrow-size {
  padding: 4px;
  border-radius: 50%;
}

.mr-gap {
  margin-right: 4px;
}

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  width: 9px;
  height: 9px;
  margin: 0 4px;
  border-radius: 50%;
  display: inline-block;
}

.dot.inactive-dot {
  background-color: #bfc2ff;
}

.dot.active-dot {
  background-color: #131599;
}
