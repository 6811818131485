.toolbar-container {
  width: 100%;
  margin: 0px auto;
  max-width: 1076px;
}

.toolbar-wrapper {
  padding: 4px 0;
  gap: 12px;
  border: 1px solid #d9daf2;
  height: 35px;
  display: flex;
  box-shadow: 0px 1px 6px 0px #02006e0d 0px 3px 5px 0px #02006e00;
  /* margin-top: 8px; */
  align-items: center;
  border-radius: 99px;
  background-color: #fff;
}

.toolbar-tab {
  padding: 2.5px 10px !important;
  margin-left: 2px;
}

.toolbar-title {
  /* padding: 10px 0; */
  font-size: 13px !important;
  font-family: Poppins-Regular !important;
  font-weight: 500 !important;
}
