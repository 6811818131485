.holidays-main-header {
  max-width: 1076px;
  width: 100%;
  margin: 0px auto;
  padding: 12px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.holidays-breadcrumbs-link {
  font-family: "Poppins-Regular" !important;
  font-size: 12px;
  font-weight: 400;
  color: #212231 !important;
  text-decoration: none !important;
}

.holidays-main-header-title {
  color: #14151f;
  font-family: "Poppins-Semibold" !important;
  font-size: 20px !important;
  font-weight: 600;
  line-height: 32px;
}

.holidays-tabs-container {
  max-width: 1076px;
  width: 100%;
  margin: 0px auto;
  position: relative;
}

.holidays-tab {
  min-width: auto;
  padding: 10px 15px;
  font-family: "Poppins-Medium" !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: #212231 !important;
}

.holidays-calendar-download {
  padding: 12px 24px;
  background-color: #3d43bb !important;
  border-radius: 42px !important;
  color: #ffffff !important;
  font-family: "Poppins-Medium" !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.holidays-calendar-download:hover {
  background-color: #3d43bb;
  opacity: 0.8;
}

.holidays-calendar-date {
  padding: 10px 46px;
  background-color: #ffffff;
  color: #3d3e5c;
  font-family: "Poppins-Medium" !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.holidays-calendar-prev {
  background-color: #f1efff;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 8px 4px;
  height: 40px;
  cursor: pointer;
}

.holidays-calendar-next {
  background-color: #f1efff;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 8px 4px;
  height: 40px;
  cursor: pointer;
}

.holidays-info-button {
  padding: 10px !important;
  background-color: #e0e0ff !important;
  border-radius: 42px !important;
  min-width: auto !important;
}

.holidays-info-tooltip {
  padding: 15px;
  padding-bottom: 28px;
  border-radius: 12px;
  box-shadow: 0px 4px 11px rgba(2, 0, 110, 0.19);
  background-color: #ffffff;
  width: 315px;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 10;
}

.holidays-info-tooltip-content {
  font-family: "Poppins-Regular" !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 20px;
  color: #3d43bb;
  width: 270px;
}

.holidays-listing {
  max-width: 1076px;
  width: 100%;
  margin: 0px auto;
  padding-top: 24px;
}
