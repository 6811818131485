.contact-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.contact-icon {
  margin-right: 8px;
  color: var(--color-brand-primary-blue-600);
  width: 20px !important;
  height: 20px !important;
}

.contact-value {
  text-align: right;
  color: var(--color-primitives-black-black);
}

.no-contact-icon {
  width: 64px !important;
  height: 64px !important;
  color: var(--color-brand-primary-grey-400);
}

.edit-contact-form {
  .form-row {
    &.two-columns {
      display: flex;
      gap: 16px;

      > * {
        flex: 1;
      }
    }

    &.three-columns {
      display: flex;
      gap: 16px;

      > * {
        flex: 1;
      }

      .zip-code-input {
        flex: 0 0 183px;
      }
    }
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    margin-block: 8px;
    gap: 8px;
  }
}

.edit-emergency-contact-form {
  .form-row {
    &.two-columns {
      display: flex;
      gap: 16px;

      > * {
        flex: 1;
      }
    }

    &.three-columns {
      display: flex;
      gap: 16px;

      > * {
        flex: 1;
      }
    }
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    margin-block: 8px;
    gap: 8px;
  }
}

.admin-contact-address {
  width: 60%;
  text-align: right;
}
