.snackbar-container {
  position: fixed;
  bottom: 16px;
  z-index: 10000;
}

.snackbar {
  min-width: 348px;
  height: 46px !important;
  max-height: 46px !important;
  padding-block: 6px !important;
  width: 100%;
  color: var(--color-text-on-action-white);
  border-radius: var(--border-radius-sm);
}

.snackbar-success {
  background-color: var(--color-primitives-neutral-900);
}

.snackbar-error {
  background-color: #d32f2f;
}

.snackbar-content {
  display: flex;
  align-items: center;
}

.snackbar-icon {
  font-size: 24px !important;
}

.snackbar-alert-icon {
  font-size: 22px !important;
}

.snackbar-close-icon {
  font-size: 24px !important;
}

.snackbar-alert-close-icon {
  font-size: 20px !important;
}

.snackbar-actions {
  display: flex;
  align-items: center;
}

.snackbar-close-button {
  color: var(--color-text-on-action-white) !important;
  padding: 0 !important;
}
.snackbar-call-to-action-link {
  margin-left: 16px !important;
}
