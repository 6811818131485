.pending-leaves-time-icon {
  padding: 0.5rem 1rem;
}

.rectangle {
  border: 1px solid #131599;
  width: 38px;
  height: 38px;
  border-radius: 6.67px;
}

.rectangle-multiple {
  background-color: #131599;
}

.upcoming-leaves-range-label-wrapper {
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background: #f1efff;
  width: 100%;
  padding: 0.2rem 0.5rem;
  margin-bottom: 1.5rem;
}

.upcoming-leaves-range-label {
  font-family: Poppins-Regular;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.upcoming-leaves-range-right {
  margin-left: 0.5rem;
}

.upcoming-leaves-chevron-left {
  transform: rotate(90deg);
  background: white;
  clip-path: circle();
}

.upcoming-leaves-chevron-right {
  transform: rotate(270deg);
  background: white;
  clip-path: circle();
}

.calendar-dot-indicator-wrapper {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.calendar-dot-indicator-orange {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background: rgb(255, 194, 0);
}

.calendar-dot-indicator-green {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background: rgb(30, 227, 207);
}

.leaves-list {
  height: 26rem;
  overflow: scroll !important;
}

.rdrDayToday .rdrDayNumber span:after {
  bottom: 1px;
}
