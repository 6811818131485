.tab-container {
  width: 100%;
  max-width: 1076px;
  margin: 0 auto;
}

.user-name-role-text-color {
  color: var(--color-text-body-secondary) !important;
}

.user-mail-text-color {
  color: var(--color-text-body-primary) !important;
}

.edit-delete-icon-size {
  width: 24px !important;
  height: 24px !important;
  color: var(--color-icon-secondary) !important;
}

.edit-delete-icon-size:disabled {
  color: var(--color-surface-action-primary-disabled) !important;
}

.users-list > div:not(:last-child) {
  border-bottom: 1px solid var(--color-border-primary);
}
