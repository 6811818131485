.top-header-wrapper {
  padding: 8px 40px;
  border-bottom: 1px solid var(--color-border-primary);
}

.header-link-divider {
  color: var(--color-text-body-primary);
  width: 7px;
  height: 24px;
  font-family: Roboto;
  font-size: 16px;
  font-style: var(--font-style-normal);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.header-divider-wrapper {
  display: flex;
  width: 23px;
  flex-direction: column;
  align-items: center;
}

.header-link {
  color: var(--color-text-body-primary);
  text-decoration: none !important;
}

.header-link-secondary {
  color: var(--color-text-body-secondary);
  text-decoration: none !important;
}

.button-secondary-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: var(--color-icon-primary);
}

.button-primary-icon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: white;
}
